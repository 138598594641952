const baseRouter = '/api/';

export const ApiRouters = {
	LOGIN: baseRouter + 'Auth/PortalLogin',
	FORGOT_PASSWORD: baseRouter + 'Auth/ForgotPassword',
	CHECK_TOKEN_RESET_PW: baseRouter + 'Auth/CheckResetPassword',
	RESET_PASSWORD: baseRouter + 'Auth/ResetPassword',

	ACCOUNT: baseRouter + 'Account',
	USER_LOGIN_PROFILE: baseRouter + 'Account/Profile',
	CHANGE_PASSWORD: baseRouter + 'Account/ChangePassword',

	CUSTOMER: baseRouter + 'Customers',
	CUSTOMER_TYPE: baseRouter + 'CustomerTypes',
	USER: baseRouter + 'Users',
	COMMON: baseRouter + 'Commons',
	ROLE: baseRouter + 'Groups',
	CAREER: baseRouter + 'Careers',
	SUBCRIPTIONPACKAGE: baseRouter + 'SubcriptionPackages',
	TOPIC: baseRouter + 'Topics',
	SENTENCE: baseRouter + 'Sentences',
	SENTENCEVOCAKEY: baseRouter + 'SentenceVocakeys',
	LEARNER: baseRouter + 'Learners',
	GRAMMARDESCRIPTION: baseRouter + 'GrammarDescriptions',
	STATICPAGE: baseRouter + 'StaticPages',
	SETTING: baseRouter + 'Settings',
	REPORT: baseRouter + 'Reports',
	IMPORT: baseRouter + 'Import',
	EXPORT: baseRouter + 'Export',
	NOTIFICATION: baseRouter + 'Notification',
	LEARNINGPROCESS: baseRouter + 'LearningProcess',
	DASHBOARD: baseRouter + 'Dashboard',
	HOMEPAGE: baseRouter + 'HomePages',
	SUBSCRIPTIONREQUEST: baseRouter + 'LearnerSubscriptions',
	SUBSCRIPTIONTEACHERREQUEST: baseRouter + 'TeacherSubscriptions',
	TEACHER: baseRouter + 'Teachers',
	LEVEL: baseRouter + 'Levels',
	CLASS: baseRouter + 'Class',

	// v4
	FOLDER: baseRouter + 'FolderManagement',

	// v5
	VOCABULARY: baseRouter + 'CategoryVocabulary',
	GIFT_CODES: baseRouter + 'GiftCodes',

	// v6
	REQUEST_VOCA: baseRouter + 'VocabularyRequirement',
	REPORT_ISSUE: baseRouter + 'ErrorSentence/BO',
};
