const actions = {
	GET_TEACHERS: 'GET_TEACHERS',
	GET_TEACHERS_SUCCESS: 'GET_TEACHERS_SUCCESS',
	GET_TEACHERS_ERROR: 'GET_TEACHERS_ERROR',

	GET_TEACHER_DETAIL: 'GET_TEACHER_DETAIL',
	GET_TEACHER_DETAIL_SUCCESS: 'GET_TEACHER_DETAIL_SUCCESS',
	GET_TEACHER_DETAIL_ERROR: 'GET_TEACHER_DETAIL_ERROR',

	GET_SUBCRIPTION_TEACHER_DETAIL: 'GET_SUBCRIPTION_TEACHER_DETAIL',
	GET_SUBCRIPTION_DETAIL_TEACHER_SUCCESS: 'GET_SUBCRIPTION_DETAIL_TEACHER_SUCCESS',
	GET_SUBCRIPTION_DETAIL_TEACHER_ERROR: 'GET_SUBCRIPTION_DETAIL_TEACHER_ERROR',

	CREATE_TEACHER: 'CREATE_TEACHER',
	CREATE_SUBCRIPTION_TEACHER: 'CREATE_SUBCRIPTION_TEACHER',
	UPDATE_SUBCRIPTION_TEACHER: 'UPDATE_SUBCRIPTION_TEACHER',
	UPDATE_TEACHER: 'UPDATE_TEACHER',
	DELETE_TEACHER: 'DELETE_TEACHER',

	GET_SUBCRIPTIONS_TEACHER: 'GET_SUBCRIPTIONS_TEACHER',
	GET_SUBCRIPTIONS_TEACHER_SUCCESS: 'GET_SUBCRIPTIONS_TEACHER_SUCCESS',

	GET_LEARNVOCAKEYS_TEACHER: 'GET_LEARNVOCAKEYS_TEACHER',
	GET_LEARNVOCAKEYS_TEACHER_SUCCESS: 'GET_LEARNVOCAKEYS_TEACHER_SUCCESS',

	GET_CLASS_BY_TEACHER: 'GET_CLASS_BY_TEACHER',
	GET_CLASS_BY_TEACHER_SUCCESS: 'GET_CLASS_BY_TEACHER_SUCCESS',
	GET_CLASS_BY_TEACHER_ERROR: 'GET_CLASS_BY_TEACHER_ERROR',

	GET_CLASS_BY_MANAGER: 'GET_CLASS_BY_MANAGER',
	GET_CLASS_BY_MANAGER_SUCCESS: 'GET_CLASS_BY_MANAGER_SUCCESS',
	GET_CLASS_BY_MANAGER_ERROR: 'GET_CLASS_BY_MANAGER_ERROR',

	// v4
	GET_FOLDERS: 'GET_FOLDERS',
	GET_FOLDERS_SUCCESS: 'GET_FOLDERS_SUCCESS',

	GET_MODULES: 'GET_MODULES',
	GET_MODULES_SUCCESS: 'GET_MODULES_SUCCESS',

	GET_MODULE_VOCAKEYS: 'GET_MODULE_VOCAKEYS',
	GET_MODULE_VOCAKEYS_SUCCESS: 'GET_MODULE_VOCAKEYS_SUCCESS',

	UPDATE_AUTHOR_FEATURE: 'UPDATE_AUTHOR_FEATURE',

	TEACHER_LOADING_FALSE: 'TEACHER_LOADING_FALSE',
	//#region CRUD

	getTeachers: (model) => ({
		type: actions.GET_TEACHERS,
		payload: { model },
	}),

	getClassByTeacher: (model, id) => ({
		type: actions.GET_CLASS_BY_TEACHER,
		payload: { model, id },
	}),

	getClassByManager: (model, id) => ({
		type: actions.GET_CLASS_BY_MANAGER,
		payload: { model, id },
	}),

	createTeacher: (model, cbSuccess, cbError) => ({
		type: actions.CREATE_TEACHER,
		payload: { model },
		cbSuccess,
		cbError,
	}),
	updateTeacher: (model, cbSuccess, cbError) => ({
		type: actions.UPDATE_TEACHER,
		payload: { model },
		cbSuccess,
		cbError,
	}),
	deleteTeacher: (id, cbSuccess, cbError) => ({
		type: actions.DELETE_TEACHER,
		payload: { id },
		cbSuccess,
		cbError,
	}),
	getTeacherDetail: (id) => ({
		type: actions.GET_TEACHER_DETAIL,
		payload: { id },
	}),

	createSubcription: (model, teacherId, cbSuccess, cbError) => ({
		type: actions.CREATE_SUBCRIPTION_TEACHER,
		payload: { model, teacherId },
		cbSuccess,
		cbError,
	}),
	getSubcriptions: (model, teacherId) => ({
		type: actions.GET_SUBCRIPTIONS_TEACHER,
		payload: { model, teacherId },
	}),
	getSubcriptionDetail: (id) => ({
		type: actions.GET_SUBCRIPTION_TEACHER_DETAIL,
		payload: { id },
	}),
	updateSubcription: (model, cbSuccess, cbError) => ({
		type: actions.UPDATE_SUBCRIPTION_TEACHER,
		payload: { model },
		cbSuccess,
		cbError,
	}),
	getLearnVocakeys: (model, teacherId) => ({
		type: actions.GET_LEARNVOCAKEYS_TEACHER,
		payload: { model, teacherId },
	}),

	// v4
	getFolders: (model) => ({
		type: actions.GET_FOLDERS,
		payload: { model },
	}),

	getModules: (model) => ({
		type: actions.GET_MODULES,
		payload: { model },
	}),

	getModuleVocakeys: (model) => ({
		type: actions.GET_MODULE_VOCAKEYS,
		payload: { model },
	}),

	updateAuthorizationForTeacher: (model, id, cbError) => ({
		type: actions.UPDATE_AUTHOR_FEATURE,
		payload: { model, id },
		cbError,
	}),
};

export default actions;
