import actions from './actions';

const initState = {
	reportIssueList: [],
	errorSentenceList: [],
	loading: false,
	totalItems: 0,
	totalErrors: 0,
};

export default function reportIssueReducer(state = initState, action) {
	switch (action.type) {
		case actions.GET_REPORT_ISSUE_LIST:
			return { ...state, loading: true, totalItems: 0 };

		case actions.GET_REPORT_ISSUE_LIST_SUCCESS:
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, reportIssueList: res, totalItems, loading: false };

		case actions.GET_ERROR_SENTENCE:
			return { ...state, loading: true, totalErrors: 0 };

		case actions.GET_ERROR_SENTENCE_SUCCESS:
			let { data: errorSentenceData, totalItems: errorSentenceTotalItems } = action.payload;
			let errorSentenceRes = errorSentenceData ? errorSentenceData : [];
			return { ...state, errorSentenceList: errorSentenceRes, totalErrors: errorSentenceTotalItems, loading: false };

		case actions.REPORT_ISSUE_LOADING_FALSE: {
			return { ...state, loading: false };
		}

		default:
			return state;
	}
}
