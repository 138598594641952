import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as subcriptionPackageServices from '@iso/services/subcriptionPackage';

//#region CRUD
export function* getSubcriptionPackages() {
	yield takeLatest(actions.GET_SUBCRIPTIONPACKAGES, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(subcriptionPackageServices.getSubcriptionPackages, model);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_SUBCRIPTIONPACKAGES_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({ type: actions.SUBCRIPTIONPACKAGE_LOADING_FALSE });
		}
	});
}
export function* createSubcriptionPackage() {
	yield takeLatest(actions.CREATE_SUBCRIPTIONPACKAGE, function* ({ payload, cbSuccess, cbError }) {
		const { model } = payload;
		try {
			var response = yield call(subcriptionPackageServices.createSubcriptionPackage, model);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* updateSubcriptionPackage() {
	yield takeLatest(actions.UPDATE_SUBCRIPTIONPACKAGE, function* ({ payload, cbSuccess, cbError }) {
		const { model } = payload;
		try {
			var response = yield call(subcriptionPackageServices.updateSubcriptionPackage, model);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* deleteSubcriptionPackage() {
	yield takeLatest(actions.DELETE_SUBCRIPTIONPACKAGE, function* ({ payload, cbSuccess, cbError }) {
		const { id } = payload;
		try {
			var response = yield call(subcriptionPackageServices.deleteSubcriptionPackage, id);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* getSubcriptionPackageDetail() {
	yield takeLatest(actions.GET_SUBCRIPTIONPACKAGE_DETAIL, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(subcriptionPackageServices.getSubcriptionPackageDetail, model);
			if (response.data.success === true) {
				var data = response.data.data;
				yield put({
					type: actions.GET_SUBCRIPTIONPACKAGE_DETAIL_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({ type: actions.SUBCRIPTIONPACKAGE_LOADING_FALSE });
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(getSubcriptionPackages),
		fork(createSubcriptionPackage),
		fork(updateSubcriptionPackage),
		fork(deleteSubcriptionPackage),
		fork(getSubcriptionPackageDetail),
	]);
}
