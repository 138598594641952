const actions = {
  GET_GRAMMARDESCRIPTIONS: "GET_GRAMMARDESCRIPTIONS",
  GET_GRAMMARDESCRIPTIONS_SUCCESS: "GET_GRAMMARDESCRIPTIONS_SUCCESS",

  GET_GRAMMARDESCRIPTION_DETAIL: "GET_GRAMMARDESCRIPTION_DETAIL",
  GET_GRAMMARDESCRIPTION_DETAIL_SUCCESS: "GET_GRAMMARDESCRIPTION_DETAIL_SUCCESS",

  CREATE_GRAMMARDESCRIPTION: "CREATE_GRAMMARDESCRIPTION",
  UPDATE_GRAMMARDESCRIPTION: "UPDATE_GRAMMARDESCRIPTION",
  DELETE_GRAMMARDESCRIPTION: "DELETE_GRAMMARDESCRIPTION",

  GRAMMARDESCRIPTION_LOADING_FALSE: 'GRAMMARDESCRIPTION_LOADING_FALSE',
  //#region CRUD

  getGrammarDescriptions: model => ({
    type: actions.GET_GRAMMARDESCRIPTIONS,
    payload: { model }
  }),

  createGrammarDescription: (model, cbSuccess, cbError) => ({
    type: actions.CREATE_GRAMMARDESCRIPTION,
    payload: { model },
    cbSuccess,
    cbError
  }),
  updateGrammarDescription: (model, cbSuccess, cbError) => ({
    type: actions.UPDATE_GRAMMARDESCRIPTION,
    payload: { model },
    cbSuccess,
    cbError
  }),
  deleteGrammarDescription: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_GRAMMARDESCRIPTION,
    payload: { id },
    cbSuccess,
    cbError
  }),
  getGrammarDescriptionDetail: id => ({
    type: actions.GET_GRAMMARDESCRIPTION_DETAIL,
    payload: { id }
  }),
};

export default actions;
