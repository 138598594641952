import actions from "./actions";

const initState = {  
  imageSlider: [],
  imageTrial: [],
  staticPages: [],
  staticPageDetail: {},
  permissions: {},
  loading: false,
  totalItems: 0,

};

export default function staticPageReducer(state = initState, action) {
  switch (action.type) {
    //#region IMAGE SLIDER
    case actions.GET_IMAGE_SLIDER:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_IMAGE_SLIDER_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, imageSlider: res, loading: false };
    }
    case actions.IMAGE_SLIDER_LOADING_FALSE: {
      return { ...state, loading: false };
    }

    //#region IMAGE TRIAL
    case actions.GET_IMAGE_TRIAL:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_IMAGE_TRIAL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, imageTrial: res, loading: false };
    }
    case actions.IMAGE_TRIAL_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    
    //#region STATIC PAGE
    case actions.GET_STATICPAGES:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_STATICPAGES_SUCCESS:
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, staticPages: res, totalItems, loading: false };

    case actions.GET_STATICPAGE_DETAIL:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_STATICPAGE_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, staticPageDetail: res, loading: false };
    }
    case actions.STATICPAGE_LOADING_FALSE:{
      return { ...state,  loading: false };
    }

    default:
      return state;
  }
}
