import { api } from "@iso/utils/axios.configs";
import { ApiRouters } from "@iso/utils/apiRouters";

export const addImageSlider = body => {
  var url = ApiRouters.STATICPAGE;
  return api.post(url, body);
};
export const getImageSlider = () => {
  var url = `${ApiRouters.STATICPAGE}/HomeSliderPortal`;
  return api.get(url);
};
export const updateImageSlider = body => {
  var url = `${ApiRouters.STATICPAGE}/HomeSlider`;
  return api.put(url, body);
};

export const getStaticPages = body => {
  var url = `${ApiRouters.STATICPAGE}/StaticPageManagement`;
  return api.get(url, { params: body });
};
export const updateStaticPage = body => {
  var url = `${ApiRouters.STATICPAGE}/StaticPageManagement/${body.id}`;
  return api.put(url,body);
};
export const getStaticPageDetail = id => {
  var url = `${ApiRouters.STATICPAGE}/StaticPageManagement/${id}`;
  return api.get(url);
};

export const updateSliderMulti = body => {
  var url = `${ApiRouters.STATICPAGE}/HomeSliderMulti`;
  return api.put(url, body);
};
export const getImageTrial = () => {
  var url = `${ApiRouters.STATICPAGE}/ImageTrialPortal`;
  return api.get(url);
};
export const updateImageTrial = body => {
  var url = `${ApiRouters.STATICPAGE}/ImageTrial`;
  return api.put(url, body);
};
