const actions = {
	GET_VOCABULARY: 'GET_VOCABULARY',
	GET_VOCABULARY_SUCCESS: 'GET_VOCABULARY_SUCCESS',

	GET_CLASS_IN_VOCABULARY: 'GET_CLASS_IN_VOCABULARY',
	GET_CLASS_IN_VOCABULARY_SUCCESS: 'GET_CLASS_IN_VOCABULARY_SUCCESS',

	VOCABULARY_LOADING_FALSE: 'VOCABULARY_LOADING_FALSE',

	getVocabulary: (model) => ({
		type: actions.GET_VOCABULARY,
		payload: { model },
	}),

	getClassInVocabulary: (model) => ({
		type: actions.GET_CLASS_IN_VOCABULARY,
		payload: { model },
	}),
};

export default actions;
