const actions = {
	GET_DISCOUNT_CODES: 'GET_DISCOUNT_CODES',
	GET_DISCOUNT_CODES_SUCCESS: 'GET_DISCOUNT_CODES_SUCCESS',

	DISCOUNT_LOADING_FALSE: 'DISCOUNT_LOADING_FALSE',

	getDiscountCodes: (model) => ({
		type: actions.GET_DISCOUNT_CODES,
		payload: { model },
	}),
};

export default actions;
