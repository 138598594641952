import actions from "./actions";

const initState = {  
  settings: [],
  settingDetail: {},
  permissions: {},
  loading: false,
  totalItems: 0,

};

export default function settingReducer(state = initState, action) {
  switch (action.type) {
    //#region CRUD
    case actions.GET_SETTINGS:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_SETTINGS_SUCCESS:
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, settings: res, totalItems, loading: false };

    case actions.GET_SETTING_DETAIL:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_SETTING_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, settingDetail: res, loading: false };
    }
    case actions.SETTING_LOADING_FALSE:{
      return { ...state,  loading: false };
    }

    default:
      return state;
  }
}
