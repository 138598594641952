const actions = {
  GET_CONTENTS: "GET_CONTENTS",
  GET_CONTENTS_SUCCESS: "GET_CONTENTS_SUCCESS",
  UPDATE_CONTENT_DETAIL: "UPDATE_CONTENT_DETAIL",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  GET_IMAGE: "GET_IMAGE",
  GET_IMAGE_SUCCESS: "GET_IMAGE_SUCCESS",
  CONTENT_DETAIL_LOADING_FALSE: 'CONTENT_DETAIL_LOADING_FALSE',
  //#region CRUD

  getContents: model => ({
    type: actions.GET_CONTENTS,
    payload: { model }
  }),
  updateContentDetail: (model, cbSuccess, cbError) => ({
    type: actions.UPDATE_CONTENT_DETAIL,
    payload: { model },
    cbSuccess,
    cbError
  }),
  uploadImage: (model) => ({
    type: actions.UPLOAD_IMAGE,
    payload: { model },
  }),
  getImage: () => ({
    type: actions.GET_IMAGE,
  }),

};

export default actions;
