const actions = {
	GET_REQUEST_VOCA_LIST: 'GET_REQUEST_VOCA_LIST',
	GET_REQUEST_VOCA_LIST_SUCCESS: 'GET_REQUEST_VOCA_LIST_SUCCESS',

	REQUEST_VOCA_LOADING_FALSE: 'REQUEST_VOCA_LOADING_FALSE',

	getRequestVocaList: (model) => ({
		type: actions.GET_REQUEST_VOCA_LIST,
		payload: { model },
	}),
};

export default actions;
