import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getAllClassByCreater = (body, id) => {
	var url = `${ApiRouters.CLASS}/GetAllClassByCreater/${id}`;
	return api.get(url, { params: body });
};

export const getAllClassByManager = (body, id) => {
	var url = `${ApiRouters.CLASS}/GetAllClassByManager/${id}`;
	return api.get(url, { params: body });
};

export const getClassReportJointBO = (id) => {
	var url = `${ApiRouters.CLASS}/ClassReportJointBO/${id}`;
	return api.get(url);
};

export const getClassReportDetailBO = (model, id) => {
	var url = `${ApiRouters.CLASS}/ClassReportDetailBO/${id}`;
	return api.get(url, { params: model });
};

export const getRequestIntoClassBO = (model, id) => {
	var url = `${ApiRouters.CLASS}/RequestIntoClassBO/${id}`;
	return api.get(url, { params: model });
};

export const detailClass = (id) => {
	var url = `${ApiRouters.CLASS}/GetClassDetailBO/${id}`;
	return api.get(url);
};

// v5
export const changeCategoryVocabulary = (body) => {
	var url = `${ApiRouters.CLASS}/ClassCreated/ChangeCategoryVocabulary`;
	return api.put(url, body);
};

export const changePubllish = (body) => {
	var url = `${ApiRouters.CLASS}/ClassCreated/ChangePublish`;
	return api.put(url, body);
};

export const getSubcriptionPackagesInClass = (params) => {
	var url = `${ApiRouters.CLASS}/SubcriptionPackagesInClass`;
	return api.get(url, { params });
};


export const getSubcriptionPackagesProInClass = (params) => {
	var url = `${ApiRouters.CLASS}/SubcriptionPackagesProInClass`;
	return api.get(url, { params });
};

export const createSubcriptionPackageDefault = (body) => {
	var url = `${ApiRouters.CLASS}/CreateSubcriptionPackageDefault`;
	return api.post(url, body);
};

export const deleteSubscription = (id) => {
	var url = `${ApiRouters.SUBCRIPTIONPACKAGE}/${id}`;
	return api.delete(url);
};

export const updatePrice = (body) => {
	var url = `${ApiRouters.CLASS}/SubcriptionPackage/Price`;
	return api.put(url, body);
};

export const updateStatusSubcription = (body) => {
	var url = `${ApiRouters.CLASS}/SubcriptionPackage/Status`;
	return api.put(url, body);
};

export const updateQRSubcription = (body) => {
	var url = `${ApiRouters.CLASS}/SubcriptionPackage/Attachment`;
	return api.put(url, body);
};

export const updateInfoDetail = (body) => {
	var url = `${ApiRouters.CLASS}/InformationDetail`;
	return api.put(url, body);
};

export const getInformationDetail = (params) => {
	var url = `${ApiRouters.CLASS}/InformationDetail`;
	return api.get(url, { params });
};

