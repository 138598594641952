import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as learningProcessServices from '@iso/services/learningProcess';


//#region CRUD

export function* getGeneralInfo() {
    yield takeLatest(actions.GET_GENERAL_INFO, function* ({ payload }) {
        const { id, model } = payload;
        try {
            var response = yield call(learningProcessServices.getGeneralInfo, id, model);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_GENERAL_INFO_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_GENERAL_INFO_ERROR,
            })          
        }
    })
}
export function* getReviewInfo() {
    yield takeLatest(actions.GET_REVIEW_INFO, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(learningProcessServices.getReviewInfo, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_REVIEW_INFO_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_REVIEW_INFO_ERROR,
            })          
        }
    })
}
export function* getCompleteInfo() {
    yield takeLatest(actions.GET_COMPLETE_INFO, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(learningProcessServices.getCompleteInfo, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_COMPLETE_INFO_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_COMPLETE_INFO_ERROR,
            })          
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getGeneralInfo),
        fork(getReviewInfo),
        fork(getCompleteInfo),
    ])
}