import actions from "./actions";

const initState = {
  notifications: [],
  notificationDetail: {},
  permissions: {},
  loading: false,
  totalItems: 0,

};

export default function notificationReducer(state = initState, action) {
  switch (action.type) {
    //#region CRUD
    case actions.GET_NOTIFICATIONS:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_NOTIFICATIONS_SUCCESS:
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, notifications: res, totalItems, loading: false };

    case actions.GET_NOTIFICATION_DETAIL:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_NOTIFICATION_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, notificationDetail: res, loading: false };
    }

    case actions.NOTIFICATION_LOADING_FALSE: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
}
