const actions = {
	GET_CHART_DETAIL_CLASS: 'GET_CHART_DETAIL_CLASS',
	GET_CHART_DETAIL_CLASS_SUCCESS: 'GET_CHART_DETAIL_CLASS_SUCCESS',

	GET_REPORT_DETAIL_CLASS: 'GET_REPORT_DETAIL_CLASS',
	GET_REPORT_DETAIL_CLASS_SUCCESS: 'GET_REPORT_DETAIL_CLASS_SUCCESS',

	GET_REQUEST_INTO_CLASS: 'GET_REQUEST_INTO_CLASS',
	GET_REQUEST_INTO_CLASS_SUCCESS: 'GET_REQUEST_INTO_CLASS_SUCCESS',

	GET_CLASS_DETAIL: 'GET_CLASS_DETAIL',
	GET_CLASS_DETAIL_SUCCESS: 'GET_CLASS_DETAIL_SUCCESS',

	GET_MODULE_INCLASS: 'GET_MODULE_INCLASS',
	GET_MODULE_INCLASS_SUCCESS: 'GET_MODULE_INCLASS_SUCCESS',

	GET_REPORT_PROCESS_MODULE: 'GET_REPORT_PROCESS_MODULE',
	GET_REPORT_PROCESS_MODULE_SUCCESS: 'GET_REPORT_PROCESS_MODULE_SUCCESS',

	GET_SUBCRIPTION_PACKAGES: 'GET_SUBCRIPTION_PACKAGES',
	GET_SUBCRIPTION_PACKAGES_SUCCESS: 'GET_SUBCRIPTION_PACKAGES_SUCCESS',

	GET_SUBCRIPTION_PACKAGES_PRO: 'GET_SUBCRIPTION_PACKAGES_PRO',
	GET_SUBCRIPTION_PACKAGES_PRO_SUCCESS: 'GET_SUBCRIPTION_PACKAGES_PRO_SUCCESS',

	GET_INFORMATION_DETAIL: 'GET_INFORMATION_DETAIL',
	GET_INFORMATION_DETAIL_SUCCESS: 'GET_INFORMATION_DETAIL_SUCCESS',

	CLASS_LOADING_FALSE: 'CLASS_LOADING_FALSE',
	//#region CRUD

	getChartDetailClass: (id) => ({
		type: actions.GET_CHART_DETAIL_CLASS,
		payload: { id },
	}),

	getReportDetailClass: (model, id) => ({
		type: actions.GET_REPORT_DETAIL_CLASS,
		payload: { model, id },
	}),

	getRequestIntoClass: (model, id) => ({
		type: actions.GET_REQUEST_INTO_CLASS,
		payload: { model, id },
	}),

	getClassDetail: (id) => ({
		type: actions.GET_CLASS_DETAIL,
		payload: { id },
	}),

	getModuleInClass: (id) => ({
		type: actions.GET_MODULE_INCLASS,
		payload: { id },
	}),

	getReportProcessModule: (model) => ({
		type: actions.GET_REPORT_PROCESS_MODULE,
		payload: { model },
	}),

	getSubcriptionPackages: (model) => ({
		type: actions.GET_SUBCRIPTION_PACKAGES,
		payload: { model },
	}),

	getSubcriptionProPackagesPro: (model) => ({
		type: actions.GET_SUBCRIPTION_PACKAGES_PRO,
		payload: { model },
	}),

	getInformationDetail: (model) => ({
		type: actions.GET_INFORMATION_DETAIL,
		payload: { model },
	}),
};

export default actions;
