const actions = {
  GET_SUBSCRIPTIONREQUESTS: "GET_SUBSCRIPTIONREQUESTS",
  GET_SUBSCRIPTIONREQUESTS_SUCCESS: "GET_SUBSCRIPTIONREQUESTS_SUCCESS",

  SUBSCRIPTIONREQUEST_LOADING_FALSE: 'SUBSCRIPTIONREQUEST_LOADING_FALSE',
  //#region CRUD

  getSubscriptionRequests: model => ({
    type: actions.GET_SUBSCRIPTIONREQUESTS,
    payload: { model }
  }),

  GET_SUBSCRIPTIONTEACHERREQUESTS: "GET_SUBSCRIPTIONTEACHERREQUESTS",
  GET_SUBSCRIPTIONTEACHERREQUESTS_SUCCESS: "GET_SUBSCRIPTIONTEACHERREQUESTS_SUCCESS",

  SUBSCRIPTIONTEACHERREQUEST_LOADING_FALSE: 'SUBSCRIPTIONTEACHERREQUEST_LOADING_FALSE',
  //#region CRUD

  getSubscriptionTeacherRequests: model => ({
    type: actions.GET_SUBSCRIPTIONTEACHERREQUESTS,
    payload: { model }
  }),
};

export default actions;
