import { ApiRouters } from '@iso/utils/apiRouters';
import { api, exportAxios } from "@iso/utils/axios.configs";
import axios from 'axios';

export const getLearningQuality = params => {
  var url = `${ApiRouters.REPORT}/LearningQuality`;
  return api.get(url, { params });
};
export const getDemographicReportByAge = params => {
  var url = `${ApiRouters.REPORT}/DemographicReportByAge`;
  return api.get(url, { params });
};
export const getDemographicReportByGender = params => {
  var url = `${ApiRouters.REPORT}/DemographicReportByGender`;
  return api.get(url, { params });
};
export const getDemographicReportByCareer = params => {
  var url = `${ApiRouters.REPORT}/DemographicReportByCareer`;
  return api.get(url, { params });
};
export const getDemographicReportByNation = params => {
  var url = `${ApiRouters.REPORT}/DemographicReportByNation`;
  return api.get(url, { params });
};
export const getDemographicReportByProvince = params => {
  var url = `${ApiRouters.REPORT}/DemographicReportByProvince`;
  return api.get(url, { params });
};
export const getUserStatisticReportByRegisterType = params => {
  var url = `${ApiRouters.REPORT}/UserStatisticReportByRegisterType`;
  return api.get(url, { params });
};
export const getUserStatisticReportByTraffic = params => {
  var url = `${ApiRouters.REPORT}/UserStatisticReportByTraffic`;
  return api.get(url, { params });
};
export const getUserStatisticReportByPackage = params => {
  var url = `${ApiRouters.REPORT}/UserStatisticReportByPackage`;
  return api.get(url, { params });
};
//#region EXPORT
export const exportLearningQualityReport = body => {
  var url = `${ApiRouters.REPORT}/LearningQualityExport`;
  return axios(exportAxios(url, body));
};
export const exportDemographicReportByAge = body => {
  var url = `${ApiRouters.REPORT}/DemographicReportByAgeExport`;
  return axios(exportAxios(url, body));
};
export const exportDemographicReportByGender = body => {
  var url = `${ApiRouters.REPORT}/DemographicReportByGenderExport`;
  return axios(exportAxios(url, body));
};
export const exportDemographicReportByCareer = body => {
  var url = `${ApiRouters.REPORT}/DemographicReportByCareerExport`;
  return axios(exportAxios(url, body));
};
export const exportDemographicReportByNation = body => {
  var url = `${ApiRouters.REPORT}/DemographicReportByNationExport`;
  return axios(exportAxios(url, body));
};
export const exportDemographicReportByProvince = body => {
  var url = `${ApiRouters.REPORT}/DemographicReportByProvinceExport`;
  return axios(exportAxios(url, body));
};
export const exportUserStatisticReportByRegisterType = body => {
  var url = `${ApiRouters.REPORT}/UserStatisticReportByRegisterTypeExport`;
  return axios(exportAxios(url, body));
};
export const exportUserStatisticReportByTraffic = body => {
  var url = `${ApiRouters.REPORT}/UserStatisticReportByTrafficExport`;
  return axios(exportAxios(url, body));
};
export const exportUserStatisticReportByPackage = body => {
  var url = `${ApiRouters.REPORT}/UserStatisticReportByPackageExport`;
  return axios(exportAxios(url, body));
};