import { all } from 'redux-saga/effects';
import authSaga from '@iso/redux/auth/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import accountSaga from '@iso/redux/account/saga';
import commonSaga from '@iso/redux/common/saga';
import userSaga from '@iso/redux/users/saga';
import roleSaga from '@iso/redux/roles/saga';
import careerSaga from '@iso/redux/careers/saga';
import subcriptionPackageSaga from '@iso/redux/subcriptionPackages/saga';
import topicSaga from '@iso/redux/topics/saga';
import sentenceSaga from '@iso/redux/sentences/saga';
import learnerSaga from '@iso/redux/learners/saga';
import staticPageSaga from '@iso/redux/staticPages/saga';
import grammarDescriptionSaga from '@iso/redux/grammarDescriptions/saga';
import settingSaga from '@iso/redux/settings/saga';
import reportSaga from '@iso/redux/reports/saga';
import importSaga from '@iso/redux/imports/saga';
import exportSaga from '@iso/redux/exports/saga';
import notificationSaga from '@iso/redux/notifications/saga';
import learningProcessSaga from '@iso/redux/learningProcess/saga';
import dashboardSaga from '@iso/redux/dashboard/saga';
import homePageSaga from '@iso/redux/homePages/saga';
import subscriptionRequestSaga from '@iso/redux/subscriptionRequests/saga';
import teacherSaga from '@iso/redux/teachers/saga';
import levelSaga from '@iso/redux/levels/saga';
import classSaga from '@iso/redux/class/saga';
import vocabularySaga from '@iso/redux/vocabulary/saga';
import discountSaga from '@iso/redux/discount/saga';
import requestVocaSaga from '@iso/redux/requestVoca/saga';
import reportIssue from '@iso/redux/reportIssue/saga';

export default function* rootSaga(getState) {
	yield all([
		authSaga(),
		ecommerceSaga(),
		accountSaga(),
		commonSaga(),
		userSaga(),
		roleSaga(),
		careerSaga(),
		subcriptionPackageSaga(),
		topicSaga(),
		sentenceSaga(),
		learnerSaga(),
		grammarDescriptionSaga(),
		staticPageSaga(),
		settingSaga(),
		reportSaga(),
		importSaga(),
		exportSaga(),
		notificationSaga(),
		learningProcessSaga(),
		dashboardSaga(),
		homePageSaga(),
		subscriptionRequestSaga(),
		teacherSaga(),
		levelSaga(),
		classSaga(),
		vocabularySaga(),
		discountSaga(),
		requestVocaSaga(),
		reportIssue(),
	]);
}
