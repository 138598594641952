import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as classServices from '@iso/services/class';
import * as teacherServices from '@iso/services/teacher';

//#region CRUD

export function* getClassReportJointBO() {
	yield takeEvery(actions.GET_CHART_DETAIL_CLASS, function* ({ payload }) {
		const { id } = payload;
		try {
			var response = yield call(classServices.getClassReportJointBO, id);
			if (response.data.success === true) {
				var data = response.data.data;
				yield put({
					type: actions.GET_CHART_DETAIL_CLASS_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export function* getClassReportDetailBO() {
	yield takeEvery(actions.GET_REPORT_DETAIL_CLASS, function* ({ payload }) {
		const { model, id } = payload;
		try {
			var response = yield call(classServices.getClassReportDetailBO, model, id);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_REPORT_DETAIL_CLASS_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export function* getRequestIntoClassBO() {
	yield takeEvery(actions.GET_REQUEST_INTO_CLASS, function* ({ payload }) {
		const { id, model } = payload;
		try {
			var response = yield call(classServices.getRequestIntoClassBO, model, id);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_REQUEST_INTO_CLASS_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export function* getDetailClass() {
	yield takeEvery(actions.GET_CLASS_DETAIL, function* ({ payload }) {
		const { id } = payload;
		try {
			var response = yield call(classServices.detailClass, id);
			if (response.data.success === true) {
				var data = response.data.data;
				yield put({
					type: actions.GET_CLASS_DETAIL_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export function* getModuleInClass() {
	yield takeEvery(actions.GET_MODULE_INCLASS, function* ({ payload }) {
		const { id } = payload;
		try {
			var response = yield call(teacherServices.getModuleInClass, id);
			if (response.data.success === true) {
				var data = response.data.data;
				yield put({
					type: actions.GET_MODULE_INCLASS_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export function* getReportProcessModule() {
	yield takeEvery(actions.GET_REPORT_PROCESS_MODULE, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(teacherServices.getReportProcessModule, model);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_REPORT_PROCESS_MODULE_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export function* getSubcriptionPackages() {
	yield takeEvery(actions.GET_SUBCRIPTION_PACKAGES, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(classServices.getSubcriptionPackagesInClass, model);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_SUBCRIPTION_PACKAGES_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export function* getSubcriptionPackagesPro() {
	yield takeEvery(actions.GET_SUBCRIPTION_PACKAGES_PRO, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(classServices.getSubcriptionPackagesProInClass, model);
			if (response.data.success === true) {
				const { data, totalItems, classProId } = response.data.data;
				yield put({
					type: actions.GET_SUBCRIPTION_PACKAGES_PRO_SUCCESS,
					payload: { data, totalItems, classProId },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export function* getInformationDetail() {
	yield takeEvery(actions.GET_INFORMATION_DETAIL, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(classServices.getInformationDetail, model);
			if (response.data.success === true) {
				var data = response.data.data;

				yield put({
					type: actions.GET_INFORMATION_DETAIL_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(getClassReportJointBO),
		fork(getClassReportDetailBO),
		fork(getRequestIntoClassBO),
		fork(getDetailClass),
		fork(getModuleInClass),
		fork(getReportProcessModule),
		fork(getSubcriptionPackages),
		fork(getInformationDetail),
		fork(getSubcriptionPackagesPro),
	]);
}
