const actions = {
  GET_SENTENCES: "GET_SENTENCES",
  GET_SENTENCES_SUCCESS: "GET_SENTENCES_SUCCESS",

  GET_SENTENCE_DETAIL: "GET_SENTENCE_DETAIL",
  GET_SENTENCE_DETAIL_SUCCESS: "GET_SENTENCE_DETAIL_SUCCESS",

  CREATE_SENTENCE: "CREATE_SENTENCE",
  UPDATE_SENTENCE: "UPDATE_SENTENCE",
  DELETE_SENTENCE: "DELETE_SENTENCE",

  GET_SENTENCEVOCAKEYS: "GET_SENTENCEVOCAKEYS",
  GET_SENTENCEVOCAKEYS_SUCCESS: "GET_SENTENCEVOCAKEYS_SUCCESS",

  CREATE_SENTENCEVOCAKEY: "CREATE_SENTENCEVOCAKEY",
  UPDATE_SENTENCEVOCAKEY: "UPDATE_SENTENCEVOCAKEY",
  DELETE_SENTENCEVOCAKEY: "DELETE_SENTENCEVOCAKEY",

  GET_SENTENCEVOCAKEY_DETAIL: "GET_SENTENCEVOCAKEY_DETAIL",
  GET_SENTENCEVOCAKEY_DETAIL_SUCCESS: "GET_SENTENCEVOCAKEY_DETAIL_SUCCESS",
  GET_SENTENCEVOCAKEY_DETAIL_ERROR: 'GET_SENTENCEVOCAKEY_DETAIL_ERROR',

  SENTENCE_LOADING_FALSE: 'SENTENCE_LOADING_FALSE',
  //#region CRUD

  getSentences: model => ({
    type: actions.GET_SENTENCES,
    payload: { model }
  }),

  createSentence: (model, cbSuccess, cbError) => ({
    type: actions.CREATE_SENTENCE,
    payload: { model },
    cbSuccess,
    cbError
  }),
  updateSentence: (model, id, cbSuccess, cbError) => ({
    type: actions.UPDATE_SENTENCE,
    payload: { model, id },
    cbSuccess,
    cbError
  }),
  deleteSentence: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_SENTENCE,
    payload: { id },
    cbSuccess,
    cbError
  }),
  getSentenceDetail: id => ({
    type: actions.GET_SENTENCE_DETAIL,
    payload: { id }
  }),
  createSentenceVocakey: (model, sentenceId, cbSuccess, cbError) => ({
    type: actions.CREATE_SENTENCEVOCAKEY,
    payload: { model, sentenceId},
    cbSuccess,
    cbError
  }),
  getSentenceVocakeys: (model, sentenceId) => ({
    type: actions.GET_SENTENCEVOCAKEYS,
    payload: { model, sentenceId }
  }),
  getSentenceVocakeyDetail: id => ({
    type: actions.GET_SENTENCEVOCAKEY_DETAIL,
    payload: { id }
  }),
  updateSentenceVocakey: (model, id, cbSuccess, cbError) => ({
    type: actions.UPDATE_SENTENCEVOCAKEY,
    payload: { model, id },
    cbSuccess,
    cbError
  }),
  deleteSentenceVocakey: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_SENTENCEVOCAKEY,
    payload: { id },
    cbSuccess,
    cbError
  }),
};

export default actions;
