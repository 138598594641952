import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as staticPageServices from '@iso/services/staticPage';

//#region CRUD
export function* addImageSlider() {
    yield takeEvery(actions.ADD_IMAGE_SLIDER, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(staticPageServices.addImageSlider, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* updateImageSlider() {
    yield takeEvery(actions.UPDATE_IMAGE_SLIDER, function* ({ payload, _cbSuccess, _cbError }) {
        const { model } = payload;
        try {
            var response = yield call(staticPageServices.updateImageSlider, model);
            if (response.data.success === true) {
                yield _cbSuccess();
            }
        } catch (e) {
            yield _cbError(e.response?.data?.ErrorMessage);
        }
    })
}
export function* updateSliderMulti() {
    yield takeEvery(actions.UPDATE_SLIDER_MULTI, function* ({ payload, _cbSuccess, _cbError }) {
        const { model } = payload;
        try {
            var response = yield call(staticPageServices.updateSliderMulti, model);
            if (response.data.success === true) {
                yield _cbSuccess();
            }
        } catch (e) {
            yield _cbError(e.response?.data?.ErrorMessage);
        }
    })
}
export function* getImageSlider() {
    yield takeEvery(actions.GET_IMAGE_SLIDER, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(staticPageServices.getImageSlider, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_IMAGE_SLIDER_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.IMAGE_SLIDER_LOADING_FALSE })
        }
    })
}
//#region STATIC PAGE
export function* getStaticPages() {
    yield takeEvery(actions.GET_STATICPAGES, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(staticPageServices.getStaticPages, model);
            if (response.data.success === true) {
                var data = response.data.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_STATICPAGES_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.STATICPAGE_LOADING_FALSE })
        }
    })
}
export function* updateStaticPage() {
    yield takeEvery(actions.UPDATE_STATICPAGE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(staticPageServices.updateStaticPage, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* getStaticPageDetail() {
    yield takeEvery(actions.GET_STATICPAGE_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(staticPageServices.getStaticPageDetail, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_STATICPAGE_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.STATICPAGE_LOADING_FALSE })
        }
    })
}

//#region 
export function* updateImageTrial() {
    yield takeEvery(actions.UPDATE_IMAGE_TRIAL, function* ({ payload, _cbSuccess, _cbError }) {
        const { model } = payload;
        try {
            var response = yield call(staticPageServices.updateImageTrial, model);
            if (response.data.success === true) {
                yield _cbSuccess();
            }
        } catch (e) {
            yield _cbError(e.response?.data?.ErrorMessage);
        }
    })
}
export function* getImageTrial() {
    yield takeEvery(actions.GET_IMAGE_TRIAL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(staticPageServices.getImageTrial, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_IMAGE_TRIAL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.IMAGE_TRIAL_LOADING_FALSE })
        }
    })
}
//#endregion
//#region 

export default function* rootSaga() {
    yield all([
        fork(addImageSlider),
        fork(updateImageSlider),
        fork(updateSliderMulti),
        fork(getImageSlider),
        fork(getStaticPages),
        fork(updateStaticPage),
        fork(getStaticPageDetail),
        fork(getImageTrial),
        fork(updateImageTrial),
    ])
}