import { all, takeLatest, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as vocabularyServices from '@iso/services/vocabulary';

//#region CRUD
export function* getVocabulary() {
	yield takeLatest(actions.GET_VOCABULARY, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(vocabularyServices.getVocabulary, model);
			if (response.data.success === true) {
				var data = response.data.data.data;

				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_VOCABULARY_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.VOCABULARY_LOADING_FALSE,
			});
		}
	});
}

export function* getClassInVocabulary() {
	yield takeEvery(actions.GET_CLASS_IN_VOCABULARY, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(vocabularyServices.getClassInVocabulary, model);
			if (response.data.success === true) {
				var { listData, total } = response.data.data;
				yield put({
					type: actions.GET_CLASS_IN_VOCABULARY_SUCCESS,
					payload: { listData, total },
				});
			}
		} catch (e) {
			yield put({ type: actions.CLASS_LOADING_FALSE });
		}
	});
}

export default function* rootSaga() {
	yield all([fork(getVocabulary), fork(getClassInVocabulary)]);
}
