import actions from "./actions";

const initState = {
  contents: [],
  image: [],
  permissions: {},
  loading: false,
  totalItems: 0,

};

export default function contentReducer(state = initState, action) {
  switch (action.type) {
    //#region CRUD
    case actions.GET_CONTENTS:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_CONTENTS_SUCCESS:
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, contents: res, totalItems, loading: false };

    //#region IMAGE TRIAL
    case actions.GET_IMAGE:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_IMAGE_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, image: res, loading: false };
    }

    case actions.CONTENT_DETAIL_LOADING_FALSE: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
}
