import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getRequestVocaList = (params) => {
	var url = `${ApiRouters.REQUEST_VOCA}/BO/Search`;
	return api.get(url, { params });
};

export const updateRequestVocaStatus = (body) => {
	var url = `${ApiRouters.REQUEST_VOCA}/BO/UpdateStatusVocabularyRequirement`;
	return api.put(url, body);
};
