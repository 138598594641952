const actions = {
	EXPORT_TOPICS: 'EXPORT_TOPICS',
	EXPORT_SENTENCES: 'EXPORT_SENTENCES',
	EXPORT_VOCAKEYS: 'EXPORT_VOCAKEYS',
	EXPORT_SINGLEFILLOUTS: 'EXPORT_SINGLEFILLOUTS',
	EXPORT_MULTIPLEFILLOUTS: 'EXPORT_MULTIPLEFILLOUTS',

	EXPORT_TOPICS_WITH_DATA: 'EXPORT_TOPICS_WITH_DATA',
	EXPORT_SENTENCES_WITH_DATA: 'EXPORT_SENTENCES_WITH_DATA',
	EXPORT_VOCAKEYS_WITH_DATA: 'EXPORT_VOCAKEYS_WITH_DATA',
	EXPORT_SINGLEFILLOUTS_WITH_DATA: 'EXPORT_SINGLEFILLOUTS_WITH_DATA',
	EXPORT_MULTIPLEFILLOUTS_WITH_DATA: 'EXPORT_MULTIPLEFILLOUTS_WITH_DATA',

	EXPORT_REQUEST_VOCA: 'EXPORT_REQUEST_VOCA',

	EXPORT_ERROR: 'EXPORT_ERROR',
	//#region CRUD

	exportTopics: () => ({
		type: actions.EXPORT_TOPICS,
	}),
	exportSentences: () => ({
		type: actions.EXPORT_SENTENCES,
	}),
	exportVocakeys: () => ({
		type: actions.EXPORT_VOCAKEYS,
	}),
	exportSingleFillOuts: () => ({
		type: actions.EXPORT_SINGLEFILLOUTS,
	}),
	exportMultipleFillOuts: () => ({
		type: actions.EXPORT_MULTIPLEFILLOUTS,
	}),

	exportTopicsWithData: () => ({
		type: actions.EXPORT_TOPICS_WITH_DATA,
	}),
	exportSentencesWithData: () => ({
		type: actions.EXPORT_SENTENCES_WITH_DATA,
	}),
	exportVocakeysWithData: () => ({
		type: actions.EXPORT_VOCAKEYS_WITH_DATA,
	}),
	exportSingleFillOutsWithData: () => ({
		type: actions.EXPORT_SINGLEFILLOUTS_WITH_DATA,
	}),
	exportMultipleFillOutsWithData: () => ({
		type: actions.EXPORT_MULTIPLEFILLOUTS_WITH_DATA,
	}),

	exportRequestVoca: (model) => ({
		type: actions.EXPORT_REQUEST_VOCA,
		payload: { model },
	}),
};

export default actions;
