const actions = {
	GET_LEARNING_QUALITY: 'GET_LEARNING_QUALITY',
	GET_LEARNING_QUALITY_SUCCESS: 'GET_LEARNING_QUALITY_SUCCESS',
	GET_LEARNING_QUALITY_ERROR: 'GET_LEARNING_QUALITY_ERROR',

	LEARNING_QUALITY_LOADING_FALSE: 'LEARNING_QUALITY_LOADING_FALSE',

	//#region DEMOGRAPHIC
	GET_DEMOGRAPHIC_REPORT_BY_AGE: 'GET_DEMOGRAPHIC_REPORT_BY_AGE',
	GET_DEMOGRAPHIC_REPORT_BY_AGE_SUCCESS: 'GET_DEMOGRAPHIC_REPORT_BY_AGE_SUCCESS',
	GET_DEMOGRAPHIC_REPORT_BY_AGE_ERROR: 'GET_DEMOGRAPHIC_REPORT_BY_AGE_ERROR',
	DEMOGRAPHIC_REPORT_BY_AGE_LOADING_FALSE: 'DEMOGRAPHIC_REPORT_BY_AGE_LOADING_FALSE',

	GET_DEMOGRAPHIC_REPORT_BY_GENDER: 'GET_DEMOGRAPHIC_REPORT_BY_GENDER',
	GET_DEMOGRAPHIC_REPORT_BY_GENDER_SUCCESS: 'GET_DEMOGRAPHIC_REPORT_BY_GENDER_SUCCESS',
	GET_DEMOGRAPHIC_REPORT_BY_GENDER_ERROR: 'GET_DEMOGRAPHIC_REPORT_BY_GENDER_ERROR',
	DEMOGRAPHIC_REPORT_BY_GENDER_LOADING_FALSE: 'DEMOGRAPHIC_REPORT_BY_GENDER_LOADING_FALSE',

	GET_DEMOGRAPHIC_REPORT_BY_CAREER: 'GET_DEMOGRAPHIC_REPORT_BY_CAREER',
	GET_DEMOGRAPHIC_REPORT_BY_CAREER_SUCCESS: 'GET_DEMOGRAPHIC_REPORT_BY_CAREER_SUCCESS',
	GET_DEMOGRAPHIC_REPORT_BY_CAREER_ERROR: 'GET_DEMOGRAPHIC_REPORT_BY_CAREER_ERROR',
	DEMOGRAPHIC_REPORT_BY_CAREER_LOADING_FALSE: 'DEMOGRAPHIC_REPORT_BY_CAREER_LOADING_FALSE',

	GET_DEMOGRAPHIC_REPORT_BY_NATION: 'GET_DEMOGRAPHIC_REPORT_BY_NATION',
	GET_DEMOGRAPHIC_REPORT_BY_NATION_SUCCESS: 'GET_DEMOGRAPHIC_REPORT_BY_NATION_SUCCESS',
	GET_DEMOGRAPHIC_REPORT_BY_NATION_ERROR: 'GET_DEMOGRAPHIC_REPORT_BY_NATION_ERROR',
	DEMOGRAPHIC_REPORT_BY_NATION_LOADING_FALSE: 'DEMOGRAPHIC_REPORT_BY_NATION_LOADING_FALSE',

	GET_DEMOGRAPHIC_REPORT_BY_PROVINCE: 'GET_DEMOGRAPHIC_REPORT_BY_PROVINCE',
	GET_DEMOGRAPHIC_REPORT_BY_PROVINCE_SUCCESS: 'GET_DEMOGRAPHIC_REPORT_BY_PROVINCE_SUCCESS',
	GET_DEMOGRAPHIC_REPORT_BY_PROVINCE_ERROR: 'GET_DEMOGRAPHIC_REPORT_BY_PROVINCE_ERROR',
	DEMOGRAPHIC_REPORT_BY_PROVINCE_LOADING_FALSE: 'DEMOGRAPHIC_REPORT_BY_PROVINCE_LOADING_FALSE',

	GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE: 'GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE',
	GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE_SUCCESS: 'GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE_SUCCESS',
	GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE_ERROR: 'GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE_ERROR',
	USERSTATISTIC_REPORT_BY_REGISTERTYPE_LOADING_FALSE: 'USERSTATISTIC_REPORT_BY_REGISTERTYPE_LOADING_FALSE',

	GET_USERSTATISTIC_REPORT_BY_TRAFFIC: 'GET_USERSTATISTIC_REPORT_BY_TRAFFIC',
	GET_USERSTATISTIC_REPORT_BY_TRAFFIC_SUCCESS: 'GET_USERSTATISTIC_REPORT_BY_TRAFFIC_SUCCESS',
	GET_USERSTATISTIC_REPORT_BY_TRAFFIC_ERROR: 'GET_USERSTATISTIC_REPORT_BY_TRAFFIC_ERROR',
	USERSTATISTIC_REPORT_BY_TRAFFIC_LOADING_FALSE: 'USERSTATISTIC_REPORT_BY_TRAFFIC_LOADING_FALSE',

	GET_USERSTATISTIC_REPORT_BY_PACKAGE: 'GET_USERSTATISTIC_REPORT_BY_PACKAGE',
	GET_USERSTATISTIC_REPORT_BY_PACKAGE_SUCCESS: 'GET_USERSTATISTIC_REPORT_BY_PACKAGE_SUCCESS',
	GET_USERSTATISTIC_REPORT_BY_PACKAGE_ERROR: 'GET_USERSTATISTIC_REPORT_BY_PACKAGE_ERROR',
	USERSTATISTIC_REPORT_BY_PACKAGE_LOADING_FALSE: 'USERSTATISTIC_REPORT_BY_PACKAGE_LOADING_FALSE',

	EXPORT_ERROR: 'EXPORT_ERROR',
	EXPORT_LEARNINGQUALITYREPORT: 'EXPORT_LEARNINGQUALITYREPORT',
	EXPORT_DEMOGRAPHICREPORTBYAGE: 'EXPORT_DEMOGRAPHICREPORTBYAGE',
	EXPORT_DEMOGRAPHICREPORTBYGENDER: 'EXPORT_DEMOGRAPHICREPORTBYGENDER',
	EXPORT_DEMOGRAPHICREPORTBYCAREER: 'EXPORT_DEMOGRAPHICREPORTBYCAREER',
	EXPORT_DEMOGRAPHICREPORTBYNATION: 'EXPORT_DEMOGRAPHICREPORTBYNATION',
	EXPORT_DEMOGRAPHICREPORTBYPROVINCE: 'EXPORT_DEMOGRAPHICREPORTBYPROVINCE',
	EXPORT_USERSTATISTICREPORTBYREGISTERTYPE: 'EXPORT_USERSTATISTICREPORTBYREGISTERTYPE',
	EXPORT_USERSTATISTICREPORTBYPACKAGE: 'EXPORT_USERSTATISTICREPORTBYPACKAGE',
	EXPORT_USERSTATISTICREPORTBYTRAFFIC: 'EXPORT_USERSTATISTICREPORTBYTRAFFIC',
	//#endregion
	//#region CRUD
	getLearningQuality: (model) => ({
		type: actions.GET_LEARNING_QUALITY,
		payload: { model },
	}),
	getDemographicReportByAge: (model) => ({
		type: actions.GET_DEMOGRAPHIC_REPORT_BY_AGE,
		payload: { model },
	}),
	getDemographicReportByGender: (model) => ({
		type: actions.GET_DEMOGRAPHIC_REPORT_BY_GENDER,
		payload: { model },
	}),
	getDemographicReportByCareer: (model) => ({
		type: actions.GET_DEMOGRAPHIC_REPORT_BY_CAREER,
		payload: { model },
	}),
	getDemographicReportByNation: (model) => ({
		type: actions.GET_DEMOGRAPHIC_REPORT_BY_NATION,
		payload: { model },
	}),
	getDemographicReportByProvince: (model) => ({
		type: actions.GET_DEMOGRAPHIC_REPORT_BY_PROVINCE,
		payload: { model },
	}),
	getUserStatisticReportByRegisterType: (model) => ({
		type: actions.GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE,
		payload: { model },
	}),
	getUserStatisticReportByTraffic: (model) => ({
		type: actions.GET_USERSTATISTIC_REPORT_BY_TRAFFIC,
		payload: { model },
	}),
	getUserStatisticReportByPackage: (model) => ({
		type: actions.GET_USERSTATISTIC_REPORT_BY_PACKAGE,
		payload: { model },
	}),
	exportLearningQualityReport: (model) => ({
		type: actions.EXPORT_LEARNINGQUALITYREPORT,
		payload: { model },
	}),
	exportDemographicReportByAge: (model) => ({
		type: actions.EXPORT_DEMOGRAPHICREPORTBYAGE,
		payload: { model },
	}),
	exportDemographicReportByGender: (model) => ({
		type: actions.EXPORT_DEMOGRAPHICREPORTBYGENDER,
		payload: { model },
	}),
	exportDemographicReportByCareer: (model) => ({
		type: actions.EXPORT_DEMOGRAPHICREPORTBYCAREER,
		payload: { model },
	}),
	exportDemographicReportByNation: (model) => ({
		type: actions.EXPORT_DEMOGRAPHICREPORTBYNATION,
		payload: { model },
	}),
	exportDemographicReportByProvince: (model) => ({
		type: actions.EXPORT_DEMOGRAPHICREPORTBYPROVINCE,
		payload: { model },
	}),
	exportUserStatisticReportByRegisterType: (model) => ({
		type: actions.EXPORT_USERSTATISTICREPORTBYREGISTERTYPE,
		payload: { model },
	}),
	exportUserStatisticReportByTraffic: (model) => ({
		type: actions.EXPORT_USERSTATISTICREPORTBYTRAFFIC,
		payload: { model },
	}),
	exportUserStatisticReportByPackage: (model) => ({
		type: actions.EXPORT_USERSTATISTICREPORTBYPACKAGE,
		payload: { model },
	}),
};

export default actions;
