import * as reportIssueServices from '@iso/services/reportIssue';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';

export function* getReportIssues() {
	yield takeLatest(actions.GET_REPORT_ISSUE_LIST, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(reportIssueServices.getReportIssue, model);
			if (response.data.success === true) {
				var data = response.data.data.data;

				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_REPORT_ISSUE_LIST_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.REPORT_ISSUE_LOADING_FALSE,
			});
		}
	});
}

export function* getErrorSentence() {
	yield takeLatest(actions.GET_ERROR_SENTENCE, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(reportIssueServices.getErrorSentence, model);
			if (response.data.success === true) {
				var data = response.data.data.data;

				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_ERROR_SENTENCE_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.REPORT_ISSUE_LOADING_FALSE,
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(getReportIssues), fork(getErrorSentence)]);
}
