import actions from './actions';

const initState = {
	discountCodes: [],
	loading: false,
	totalItems: 0,
};

export default function teacherReducer(state = initState, action) {
	switch (action.type) {
		case actions.GET_DISCOUNT_CODES:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_DISCOUNT_CODES_SUCCESS:
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, discountCodes: res, totalItems, loading: false };

		case actions.DISCOUNT_LOADING_FALSE: {
			return { ...state, loading: false };
		}

		default:
			return state;
	}
}
