import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as importServices from '@iso/services/import';

//#region CRUD

export function* importTopics() {
    yield takeEvery(actions.IMPORT_TOPICS, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importTopics, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}

export function* importSentences() {
    yield takeEvery(actions.IMPORT_SENTENCES, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importSentences, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}

export function* importSentenceVocakeys() {
    yield takeEvery(actions.IMPORT_SENTENCEVOCAKEYS, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importSentenceVocakeys, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* importSingleFillOuts() {
    yield takeEvery(actions.IMPORT_SINGLEFILLOUTS, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importSingleFillOuts, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}

export function* importMultipleFillOuts() {
    yield takeEvery(actions.IMPORT_MULTIPLEFILLOUTS, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importMultipleFillOuts, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}

export function* importSentenceImages() {
    yield takeEvery(actions.IMPORT_SENTENCEIMAGES, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importSentenceImages, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}

export function* importTopicImages() {
    yield takeEvery(actions.IMPORT_TOPICIMAGES, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importTopicImages, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}

export function* importMaleAudio() {
    yield takeEvery(actions.IMPORT_MALEAUDIO, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importMaleAudio, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* importFemaleAudio() {
    yield takeEvery(actions.IMPORT_FEMALEAUDIO, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importFemaleAudio, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* importVocakeyAudio() {
    yield takeEvery(actions.IMPORT_VOCAKEYAUDIO, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(importServices.importVocakeyAudio, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}

export function* startImport() {
    yield takeEvery(actions.START_IMPORT, function* () {
    })
}

export function* endImport() {
    yield takeEvery(actions.END_IMPORT, function* () {
    })
}

export default function* rootSaga() {
    yield all([
        fork(importTopics),
        fork(importSentences),
        fork(importSentenceVocakeys),
        fork(importSingleFillOuts),
        fork(importMultipleFillOuts),
        fork(importSentenceImages),
        fork(importTopicImages),
        fork(importMaleAudio),
        fork(importFemaleAudio),
        fork(importVocakeyAudio),
        fork(startImport),
        fork(endImport),
    ])
}