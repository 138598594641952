import actions from "./actions";

const initState = {  
  topics: [],
  topicDetail: {},
  topicSentences: [],
  permissions: {},
  loading: false,
  totalItems: 0,

};

export default function topicReducer(state = initState, action) {
  switch (action.type) {
    //#region CRUD
    case actions.GET_TOPICS:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_TOPICS_SUCCESS:
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, topics: res, totalItems, loading: false };

    case actions.GET_TOPIC_DETAIL:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_TOPIC_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, topicDetail: res, loading: false };
    }

    case actions.GET_TOPIC_SENTENCES:
        return { ...state, loading: true, totalItems: 0 }; 
    case actions.GET_TOPIC_SENTENCES_SUCCESS: {
        let { data, totalItems  } = action.payload;
        let res = data ? data : [];
        return { ...state, topicSentences: res, totalItems, loading: false };
    }

    case actions.TOPIC_LOADING_FALSE:{
      return { ...state,  loading: false };
    }

    default:
      return state;
  }
}
