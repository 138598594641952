import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as homePageServices from '@iso/services/homePage';

//#region CRUD
export function* getContents() {
    yield takeEvery(actions.GET_CONTENTS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(homePageServices.getContents, model);
            if (response.data.success === true) {
                var data = response.data.data;
                var totalItems = response.data.total;
                yield put({
                    type: actions.GET_CONTENTS_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.CONTENT_DETAIL_LOADING_FALSE })
        }
    })
}
export function* updateContentDetail() {
    yield takeEvery(actions.UPDATE_CONTENT_DETAIL, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(homePageServices.updateContentDetail, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* uploadImage() {
    yield takeEvery(actions.UPLOAD_IMAGE, function* ({ payload }) {
        const { model } = payload;
        try {
            yield call(homePageServices.uploadImage, model);
            // var response = yield call(homePageServices.uploadImage, model);
            // if (response.data.success === true) {
            //     yield _cbSuccess();
            // }
        } catch (e) {
            // yield _cbError(e.response?.data?.ErrorMessage);
        }
    })
}
export function* getImage() {
    yield takeEvery(actions.GET_IMAGE, function* ({ payload }) {
        try {
            var response = yield call(homePageServices.getImage);
            if (response.data.success === true) {
                var data  = response.data.data;
                yield put({
                    type: actions.GET_IMAGE_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.CONTENT_DETAIL_LOADING_FALSE })
        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getContents),
        fork(updateContentDetail),
        fork(uploadImage),
        fork(getImage),
    ])
}