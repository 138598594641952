const actions = {
  GET_TOPICS: "GET_TOPICS",
  GET_TOPICS_SUCCESS: "GET_TOPICS_SUCCESS",

  GET_TOPIC_SENTENCES: "GET_TOPIC_SENTENCES",
  GET_TOPIC_SENTENCES_SUCCESS: "GET_TOPIC_SENTENCES_SUCCESS",

  GET_TOPIC_DETAIL: "GET_TOPIC_DETAIL",
  GET_TOPIC_DETAIL_SUCCESS: "GET_TOPIC_DETAIL_SUCCESS",

  CREATE_TOPIC: "CREATE_TOPIC",
  UPDATE_TOPIC: "UPDATE_TOPIC",
  DELETE_TOPIC: "DELETE_TOPIC",

  TOPIC_LOADING_FALSE: 'TOPIC_LOADING_FALSE',
  //#region CRUD

  getTopics: model => ({
    type: actions.GET_TOPICS,
    payload: { model }
  }),

  createTopic: (model, cbSuccess, cbError) => ({
    type: actions.CREATE_TOPIC,
    payload: { model },
    cbSuccess,
    cbError
  }),
  updateTopic: (model, id, cbSuccess, cbError) => ({
    type: actions.UPDATE_TOPIC,
    payload: { model, id },
    cbSuccess, cbError
  }),
  deleteTopic: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_TOPIC,
    payload: { id },
    cbSuccess,
    cbError
  }),
  getTopicDetail: id => ({
    type: actions.GET_TOPIC_DETAIL,
    payload: { id }
  }),
  getTopicSentences: (id, model) => ({
    type: actions.GET_TOPIC_SENTENCES,
    payload: { id, model }
  }),
};

export default actions;
