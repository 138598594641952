import { api } from "@iso/utils/axios.configs";
import { ApiRouters } from "@iso/utils/apiRouters";

export const getNotifications = body => {
  var url = ApiRouters.NOTIFICATION;
  return api.get(url, { params: body });
};
export const sendLearnerNotifications = body => {
  var url = `${ApiRouters.NOTIFICATION}/SendLearnerNotification`;
  return api.post(url, body );
};
export const getNotificationDetail = id => {
  var url = `${ApiRouters.NOTIFICATION}/${id}`;
  return api.get(url);
};