import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as sentenceServices from '@iso/services/sentence';

//#region CRUD
export function* getSentences() {
    yield takeLatest(actions.GET_SENTENCES, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(sentenceServices.getSentences, model);
            if (response.data.success === true) {
                var data = response.data.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_SENTENCES_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.SENTENCE_LOADING_FALSE })
        }
    })
}
export function* createSentence() {
    yield takeLatest(actions.CREATE_SENTENCE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(sentenceServices.createSentence, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* updateSentence() {
    yield takeLatest(actions.UPDATE_SENTENCE, function* ({ payload, cbSuccess, cbError }) {
        const { model, id } = payload;
        try {
            var response = yield call(sentenceServices.updateSentence, model, id);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* deleteSentence() {
    yield takeLatest(actions.DELETE_SENTENCE, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(sentenceServices.deleteSentence, id);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* getSentenceDetail() {
    yield takeLatest(actions.GET_SENTENCE_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(sentenceServices.getSentenceDetail, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_SENTENCE_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.SENTENCE_LOADING_FALSE })
        }
    })
}
export function* createSentenceVocakey() {
    yield takeLatest(actions.CREATE_SENTENCEVOCAKEY, function* ({ payload, cbSuccess, cbError }) {
        const { model, sentenceId } = payload;
        try {
            var response = yield call(sentenceServices.createSentenceVocakey, model, sentenceId);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* getSentenceVocakeys() {
    yield takeLatest(actions.GET_SENTENCEVOCAKEYS, function* ({ payload }) {
        const { model, sentenceId } = payload;
        try {
            var response = yield call(sentenceServices.getSentenceVocakeys, model, sentenceId);
            if (response.data.success === true) {
                var {data} = response.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_SENTENCEVOCAKEYS_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.SENTENCE_LOADING_FALSE })
        }
    })
}
export function* updateSentenceVocakey() {
    yield takeLatest(actions.UPDATE_SENTENCEVOCAKEY, function* ({ payload, cbSuccess, cbError }) {
        const { model, id } = payload;
        try {
            var response = yield call(sentenceServices.updateSentenceVocakey, model, id);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* getSentenceVocakeyDetail() {
    yield takeLatest(actions.GET_SENTENCEVOCAKEY_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(sentenceServices.getSentenceVocakeyDetail, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_SENTENCEVOCAKEY_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_SENTENCEVOCAKEY_DETAIL_ERROR,
            })          
        }
    })
}
export function* deleteSentenceVocakey() {
    yield takeLatest(actions.DELETE_SENTENCEVOCAKEY, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(sentenceServices.deleteSentenceVocakey, id);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getSentences),
        fork(createSentence),
        fork(updateSentence),
        fork(deleteSentence),
        fork(getSentenceDetail),
        fork(getSentenceVocakeys),
        fork(createSentenceVocakey),
        fork(updateSentenceVocakey),
        fork(deleteSentenceVocakey),
        fork(getSentenceVocakeyDetail),
    ])
}