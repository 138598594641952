import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as exportServices from '@iso/services/export';
import * as moment from 'moment';
//#region EXPORT
export function* exportTopics() {
	yield takeEvery(actions.EXPORT_TOPICS, function* () {
		try {
			var response = yield call(exportServices.exportTopics);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `Topic_template_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}
export function* exportSentences() {
	yield takeEvery(actions.EXPORT_SENTENCES, function* () {
		try {
			var response = yield call(exportServices.exportSentences);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `Sentence_template_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}
export function* exportVocakeys() {
	yield takeEvery(actions.EXPORT_VOCAKEYS, function* () {
		try {
			var response = yield call(exportServices.exportVocakeys);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `Vocakey_template_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}
export function* exportSingleFillOuts() {
	yield takeEvery(actions.EXPORT_SINGLEFILLOUTS, function* () {
		try {
			var response = yield call(exportServices.exportSingleFillOuts);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `SingleFillOut_template_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}
export function* exportMultipleFillOuts() {
	yield takeEvery(actions.EXPORT_MULTIPLEFILLOUTS, function* () {
		try {
			var response = yield call(exportServices.exportMultipleFillOuts);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `MultipleFillOut_template_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}

export function* exportTopicsWithData() {
	yield takeEvery(actions.EXPORT_TOPICS_WITH_DATA, function* () {
		try {
			var response = yield call(exportServices.exportTopicsWithData);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `Topics_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}
export function* exportSentencesWithData() {
	yield takeEvery(actions.EXPORT_SENTENCES_WITH_DATA, function* () {
		try {
			var response = yield call(exportServices.exportSentencesWithData);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `Sentences_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}
export function* exportVocakeysWithData() {
	yield takeEvery(actions.EXPORT_VOCAKEYS_WITH_DATA, function* () {
		try {
			var response = yield call(exportServices.exportVocakeysWithData);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `Vocakeys_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}
export function* exportSingleFillOutsWithData() {
	yield takeEvery(actions.EXPORT_SINGLEFILLOUTS_WITH_DATA, function* () {
		try {
			var response = yield call(exportServices.exportSingleFillOutsWithData);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `SingleFillOuts_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}
export function* exportMultipleFillOutsWithData() {
	yield takeEvery(actions.EXPORT_MULTIPLEFILLOUTS_WITH_DATA, function* () {
		try {
			var response = yield call(exportServices.exportMultipleFillOutsWithData);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `MultipleFillOuts_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}

export function* exportRequestVoca() {
	yield takeEvery(actions.EXPORT_REQUEST_VOCA, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(exportServices.exportRequestVoca, model);
			if (response.data) {
				let date = moment(new Date()).format('DD_MM_YYYY');
				const fileName = `Request_Voca_${date}.xlsx`;
				yield exportSuccess(fileName, response.data);
			}
		} catch (e) {
			yield put({ type: actions.EXPORT_ERROR });
		}
	});
}

//#endregion
function* exportSuccess(fileName, data) {
	const url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	link.remove();
	yield put({ type: actions.EXPORT_ERROR });
}
export default function* rootSaga() {
	yield all([
		fork(exportTopics),
		fork(exportSentences),
		fork(exportVocakeys),
		fork(exportSingleFillOuts),
		fork(exportMultipleFillOuts),
		fork(exportTopicsWithData),
		fork(exportSentencesWithData),
		fork(exportVocakeysWithData),
		fork(exportSingleFillOutsWithData),
		fork(exportMultipleFillOutsWithData),
		fork(exportRequestVoca),
	]);
}
