import { all, takeLatest, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as discountServices from '@iso/services/discount';

//#region CRUD
export function* getDiscountCodes() {
	yield takeLatest(actions.GET_DISCOUNT_CODES, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(discountServices.getDiscountCodes, model);
			if (response.data.success === true) {
				var data = response.data.data.data;

				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_DISCOUNT_CODES_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.DISCOUNT_LOADING_FALSE,
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(getDiscountCodes)]);
}
