import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as topicServices from '@iso/services/topic';

//#region CRUD
export function* getTopics() {
    yield takeLatest(actions.GET_TOPICS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(topicServices.getTopics, model);
            if (response.data.success === true) {
                var data = response.data.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_TOPICS_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.TOPIC_LOADING_FALSE })
        }
    })
}
export function* createTopic() {
    yield takeLatest(actions.CREATE_TOPIC, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(topicServices.createTopic, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* updateTopic() {
    yield takeLatest(actions.UPDATE_TOPIC, function* ({ payload, cbSuccess, cbError }) {
        const { model, id } = payload;
        try {
            var response = yield call(topicServices.updateTopic, model, id);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* deleteTopic() {
    yield takeLatest(actions.DELETE_TOPIC, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(topicServices.deleteTopic, id);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* getTopicDetail() {
    yield takeLatest(actions.GET_TOPIC_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(topicServices.getTopicDetail, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_TOPIC_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.TOPIC_LOADING_FALSE })
        }
    })
}
export function* getTopicSentences() {
    yield takeLatest(actions.GET_TOPIC_SENTENCES, function* ({ payload }) {
        const { id, model } = payload;
        try {
            var response = yield call(topicServices.getTopicSentences, id, model);
            if (response.data.success === true) {
                var { data } = response.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_TOPIC_SENTENCES_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.TOPIC_LOADING_FALSE })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getTopics),
        fork(createTopic),
        fork(updateTopic),
        fork(deleteTopic),
        fork(getTopicDetail),
        fork(getTopicSentences)
    ])
}