import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getLevels = (body) => {
	var url = `${ApiRouters.LEVEL}/GetLevel`;
	return api.get(url, { params: body });
};
export const createLevel = (body) => {
	var url = `${ApiRouters.LEVEL}/CreateLevel`;
	return api.post(url, body);
};
export const updateLevel = (body, id) => {
	var url = `${ApiRouters.LEVEL}/Update/${id}`;
	return api.put(url, body);
};
export const deleteLevel = (id) => {
	var url = `${ApiRouters.LEVEL}/Delete/${id}`;
	return api.delete(url);
};
export const getLevelDetail = (id) => {
	var url = `${ApiRouters.LEVEL}/Detail/${id}`;
	return api.get(url);
};
export const getAllLevels = () => {
	var url = `${ApiRouters.LEVEL}/GetAllLevel`;
	return api.get(url);
};
