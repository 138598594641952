import { api } from "@iso/utils/axios.configs";
import { ApiRouters } from "@iso/utils/apiRouters";

export const importTopics = body => {
  var url = `${ApiRouters.IMPORT}/Topics`;
  return api.post(url, body );
};
export const importSentences = body => {
  var url = `${ApiRouters.IMPORT}/Sentences`;
  return api.post(url, body );
};
export const importSentenceVocakeys = body => {
  var url = `${ApiRouters.IMPORT}/SentenceVocakeys`;
  return api.post(url, body );
};
export const importSingleFillOuts = body => {
  var url = `${ApiRouters.IMPORT}/SingleFillOuts`;
  return api.post(url, body );
};
export const importMultipleFillOuts = body => {
  var url = `${ApiRouters.IMPORT}/MultipleFillOuts`;
  return api.post(url, body );
};
export const importSentenceImages = body => {
  var url = `${ApiRouters.IMPORT}/SentenceImages`;
  return api.post(url, body );
};
export const importTopicImages = body => {
  var url = `${ApiRouters.IMPORT}/TopicImages`;
  return api.post(url, body );
};
export const importMaleAudio = body => {
  var url = `${ApiRouters.IMPORT}/MaleAudio`;
  return api.post(url, body );
};
export const importFemaleAudio = body => {
  var url = `${ApiRouters.IMPORT}/FemaleAudio`;
  return api.post(url, body );
};
export const importVocakeyAudio = body => {
  var url = `${ApiRouters.IMPORT}/VocakeyAudio`;
  return api.post(url, body );
};