import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as settingServices from '@iso/services/setting';

//#region CRUD
export function* getSettings() {
	yield takeEvery(actions.GET_SETTINGS, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(settingServices.getSettings, model);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_SETTINGS_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({ type: actions.SETTING_LOADING_FALSE });
		}
	});
}

export function* updateSetting() {
	yield takeEvery(actions.UPDATE_SETTING, function* ({ payload, cbSuccess, cbError }) {
		const { id, body } = payload;
		try {
			var response = yield call(settingServices.updateSetting, id, body);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}

export function* getSettingDetail() {
	yield takeEvery(actions.GET_SETTING_DETAIL, function* ({ payload }) {
		const { id } = payload;
		try {
			var response = yield call(settingServices.getSettingDetail, id);
			if (response.data.success === true) {
				var data = response.data.data;
				yield put({
					type: actions.GET_SETTING_DETAIL_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({ type: actions.SETTING_LOADING_FALSE });
		}
	});
}

export default function* rootSaga() {
	yield all([fork(getSettings), fork(updateSetting), fork(getSettingDetail)]);
}
