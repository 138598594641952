import actions from "./actions";

const initState = {
  loading: false,
  totalItems: 0,

};

export default function exportReducer(state = initState, action) {
  switch (action.type) {
    //#region CRUD
    case actions.EXPORT_TOPICS_WITH_DATA:
    case actions.EXPORT_SENTENCES_WITH_DATA:
    case actions.EXPORT_VOCAKEYS_WITH_DATA:
    case actions.EXPORT_SINGLEFILLOUTS_WITH_DATA:
    case actions.EXPORT_MULTIPLEFILLOUTS_WITH_DATA:
      return { ...state, loading: true };

    case actions.EXPORT_SUCCESS:
      return { ...state, loading: false };
    case actions.EXPORT_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}
