import actions from './actions';

const initState = {
	subscriptionRequests: [],
	permissions: {},
	loading: false,
	totalItems: 0,
};

export default function subcriptionRequestReducer(state = initState, action) {
	switch (action.type) {
		//#region CRUD
		case actions.GET_SUBSCRIPTIONREQUESTS:
			return { ...state, loading: true, totalItems: 0 };

		case actions.GET_SUBSCRIPTIONREQUESTS_SUCCESS:
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, subscriptionRequests: res, totalItems, loading: false };

		case actions.SUBSCRIPTIONREQUEST_LOADING_FALSE: {
			return { ...state, loading: false };
		}

		case actions.GET_SUBSCRIPTIONTEACHERREQUESTS:
			return { ...state, loading: true, totalItems: 0 };

		case actions.GET_SUBSCRIPTIONTEACHERREQUESTS_SUCCESS:
			let { data1, totalItems1 } = action.payload;
			let res1 = data1 ? data1 : [];
			return { ...state, subscriptionRequests: res1, totalItems: totalItems1, loading: false };

		case actions.SUBSCRIPTIONTEACHERREQUEST_LOADING_FALSE: {
			return { ...state, loading: false };
		}

		default:
			return state;
	}
}
