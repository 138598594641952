import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Account from '@iso/redux/account/reducer';
import Common from '@iso/redux/common/reducer';
import User from '@iso/redux/users/reducer';
import Role from '@iso/redux/roles/reducer';
import Career from '@iso/redux/careers/reducer';
import SubcriptionPackage from '@iso/redux/subcriptionPackages/reducer';
import Topic from '@iso/redux/topics/reducer';
import Sentence from '@iso/redux/sentences/reducer';
import Learner from '@iso/redux/learners/reducer';
import StaticPage from '@iso/redux/staticPages/reducer';
import GrammarDescription from '@iso/redux/grammarDescriptions/reducer';
import Setting from '@iso/redux/settings/reducer';
import Report from '@iso/redux/reports/reducer';
import Import from '@iso/redux/imports/reducer';
import Export from '@iso/redux/exports/reducer';
import Notification from '@iso/redux/notifications/reducer';
import LearningProcess from '@iso/redux/learningProcess/reducer';
import Dashboard from '@iso/redux/dashboard/reducer';
import HomePage from '@iso/redux/homePages/reducer';
import SubscriptionRequest from '@iso/redux/subscriptionRequests/reducer';
import Teacher from '@iso/redux/teachers/reducer';
import Level from '@iso/redux/levels/reducer';
import Class from '@iso/redux/class/reducer';
import Vocabulary from '@iso/redux/vocabulary/reducer';
import Discount from '@iso/redux/discount/reducer';
import RequestVoca from '@iso/redux/requestVoca/reducer';
import ReportIssue from '@iso/redux/reportIssue/reducer';

export default combineReducers({
	Auth,
	App,
	ThemeSwitcher,
	Ecommerce,
	LanguageSwitcher,
	Account,
	Common,
	User,
	Role,
	Career,
	SubcriptionPackage,
	Topic,
	Sentence,
	Learner,
	StaticPage,
	GrammarDescription,
	Setting,
	Report,
	Import,
	Export,
	Notification,
	LearningProcess,
	Dashboard,
	HomePage,
	SubscriptionRequest,
	Teacher,
	Level,
	Class,
	Vocabulary,
	Discount,
	RequestVoca,
	ReportIssue,
});
