const actions = {
	GET_REPORT_ISSUE_LIST: 'GET_REPORT_ISSUE_LIST',
	GET_REPORT_ISSUE_LIST_SUCCESS: 'GET_REPORT_ISSUE_LIST_SUCCESS',

	GET_ERROR_SENTENCE: 'GET_ERROR_SENTENCE',
	GET_ERROR_SENTENCE_SUCCESS: 'GET_ERROR_SENTENCE_SUCCESS',

	REPORT_ISSUE_LOADING_FALSE: 'REPORT_ISSUE_LOADING_FALSE',

	getReportIssue: (model) => ({
		type: actions.GET_REPORT_ISSUE_LIST,
		payload: { model },
	}),

	getErrorSentence: (model) => ({
		type: actions.GET_ERROR_SENTENCE,
		payload: { model },
	}),
};

export default actions;
