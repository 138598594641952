import actions from "./actions";

const initState = {
  learningQuality: {},
  demographicReportByAge: {},
  demographicReportByGender: {},
  demographicReportByCareer: {},
  demographicReportByNation: {},
  demographicReportByProvince: {},
  userStatisticReportByRegisterType: {},
  userStatisticReportByTraffic: {},
  userStatisticReportByPackage: {},
  loading: false,
  totalItems: 0,

};

export default function reportReducer(state = initState, action) {
  switch (action.type) {
    //#region LEARNING_QUALITY
    case actions.GET_LEARNING_QUALITY:
      return { ...state, loading: true };
    case actions.GET_LEARNING_QUALITY_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, learningQuality: res, loading: false };
    }
    case actions.GET_LEARNING_QUALITY_ERROR:
      return { ...state, loading: false, learningQuality: {} };
    case actions.LEARNING_QUALITY_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    //#endregion

    //#region DEMOGRAPHIC_REPORT_BY_AGE
    case actions.GET_DEMOGRAPHIC_REPORT_BY_AGE:
      return { ...state, loading: true };
    case actions.GET_DEMOGRAPHIC_REPORT_BY_AGE_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, demographicReportByAge: res, loading: false };
    }
    case actions.GET_DEMOGRAPHIC_REPORT_BY_AGE_ERROR:
      return { ...state, loading: false, demographicReportByAge: {} };
    case actions.DEMOGRAPHIC_REPORT_BY_AGE_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    //#endregion

    //#region DEMOGRAPHIC_REPORT_BY_GENDER
    case actions.GET_DEMOGRAPHIC_REPORT_BY_GENDER:
      return { ...state, loading: true };
    case actions.GET_DEMOGRAPHIC_REPORT_BY_GENDER_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, demographicReportByGender: res, loading: false };
    }

    case actions.GET_DEMOGRAPHIC_REPORT_BY_GENDER_ERROR:
      return { ...state, loading: false, demographicReportByGender: {} };
    case actions.DEMOGRAPHIC_REPORT_BY_GENDER_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    //#endregion

    //#region DEMOGRAPHIC_REPORT_BY_CAREER
    case actions.GET_DEMOGRAPHIC_REPORT_BY_CAREER:
      return { ...state, loading: true };
    case actions.GET_DEMOGRAPHIC_REPORT_BY_CAREER_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, demographicReportByCareer: res, loading: false };
    }

    case actions.GET_DEMOGRAPHIC_REPORT_BY_CAREER_ERROR:
      return { ...state, loading: false, demographicReportByCareer: {} };
    case actions.DEMOGRAPHIC_REPORT_BY_CAREER_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    //#endregion

    //#region DEMOGRAPHIC_REPORT_BY_NATION
    case actions.GET_DEMOGRAPHIC_REPORT_BY_NATION:
      return { ...state, loading: true };
    case actions.GET_DEMOGRAPHIC_REPORT_BY_NATION_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, demographicReportByNation: res, loading: false };
    }

    case actions.GET_DEMOGRAPHIC_REPORT_BY_NATION_ERROR:
      return { ...state, loading: false, demographicReportByNation: {} };
    case actions.DEMOGRAPHIC_REPORT_BY_NATION_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    //#endregion

    //#region DEMOGRAPHIC_REPORT_BY_PROVINCE
    case actions.GET_DEMOGRAPHIC_REPORT_BY_PROVINCE:
      return { ...state, loading: true };
    case actions.GET_DEMOGRAPHIC_REPORT_BY_PROVINCE_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, demographicReportByProvince: res, loading: false };
    }

    case actions.GET_DEMOGRAPHIC_REPORT_BY_PROVINCE_ERROR:
      return { ...state, loading: false, demographicReportByProvince: {} };
    case actions.DEMOGRAPHIC_REPORT_BY_PROVINCE_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    //#endregion

    //#region USERSTATISTIC_REPORT_BY_REGISTERTYPE
    case actions.GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE:
      return { ...state, loading: true };
    case actions.GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, userStatisticReportByRegisterType: res, loading: false };
    }

    case actions.GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE_ERROR:
      return { ...state, loading: false, userStatisticReportByRegisterType: {} };
    case actions.USERSTATISTIC_REPORT_BY_REGISTERTYPE_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    //#endregion

    //#region USERSTATISTIC_REPORT_BY_TRAFFIC
    case actions.GET_USERSTATISTIC_REPORT_BY_TRAFFIC:
      return { ...state, loading: true };
    case actions.GET_USERSTATISTIC_REPORT_BY_TRAFFIC_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, userStatisticReportByTraffic: res, loading: false };
    }

    case actions.GET_USERSTATISTIC_REPORT_BY_TRAFFIC_ERROR:
      return { ...state, loading: false, userStatisticReportByTraffic: {} };
    case actions.USERSTATISTIC_REPORT_BY_TRAFFIC_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    //#endregion

     //#region USERSTATISTIC_REPORT_BY_PACKAGE
     case actions.GET_USERSTATISTIC_REPORT_BY_PACKAGE:
      return { ...state, loading: true };
    case actions.GET_USERSTATISTIC_REPORT_BY_PACKAGE_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, userStatisticReportByPackage: res, loading: false };
    }

    case actions.GET_USERSTATISTIC_REPORT_BY_PACKAGE_ERROR:
      return { ...state, loading: false, userStatisticReportByPackage: {} };
    case actions.USERSTATISTIC_REPORT_BY_PACKAGE_LOADING_FALSE: {
      return { ...state, loading: false };
    }
    //#endregion

    default:
      return state;
  }
}
