import { api } from "@iso/utils/axios.configs";
import { ApiRouters } from "@iso/utils/apiRouters";

export const getTopics = body => {
  var url = ApiRouters.TOPIC;
  return api.get(url, { params: body });
};
export const createTopic = body => {
  var url = ApiRouters.TOPIC;
  return api.post(url, body);
};
export const updateTopic = (body, id) => {
  var url = `${ApiRouters.TOPIC}/${id}`;
  return api.put(url, body);
};
export const deleteTopic = id => {
  var url = `${ApiRouters.TOPIC}/${id}`;
  return api.delete(url);
};
export const getTopicDetail = id => {
  var url = `${ApiRouters.TOPIC}/${id}`;
  return api.get(url);
};
export const getTopicSentences = (id, params) => {
  var url = `${ApiRouters.TOPIC}/${id}/Sentences`;
  return api.get(url, { params });
};