import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getTeachers = (params) => {
	var url = ApiRouters.TEACHER;
	return api.get(url, { params });
};
export const getTeacherDetail = (id, params) => {
	var url = `${ApiRouters.TEACHER}/${id}`;
	return api.get(url, { params });
};
export const createTeacher = (body) => {
	var url = ApiRouters.TEACHER;
	return api.post(url, body);
};
export const updateTeacher = (body) => {
	var url = `${ApiRouters.TEACHER}/${body.id}`;
	return api.put(url, body);
};
export const deleteTeacher = (id) => {
	var url = `${ApiRouters.TEACHER}/${id}`;
	return api.delete(url);
};
export const createSubcription = (body, teacherId) => {
	var url = `${ApiRouters.TEACHER}/${teacherId}/Subcription`;
	return api.post(url, body);
};
export const getSubcriptions = (params, teacherId) => {
	var url = `${ApiRouters.TEACHER}/${teacherId}/Subcription`;
	return api.get(url, { params });
};
export const getSubcriptionDetail = (id, params) => {
	var url = `${ApiRouters.TEACHER}/Subcription/${id}`;
	return api.get(url, { params });
};
export const updateSubcription = (body) => {
	var url = `${ApiRouters.TEACHER}/Subcription/${body.id}`;
	return api.put(url, body);
};
export const getLearnVocakeys = (params, teacherId) => {
	var url = `${ApiRouters.TEACHER}/${teacherId}/LearnVocakey`;
	return api.get(url, { params });
};

export const getFolders = (params) => {
	var url = `${ApiRouters.FOLDER}/Teacher/Folder`;
	return api.get(url, { params });
};

export const getModules = (params) => {
	var url = `${ApiRouters.FOLDER}/Teacher/Module`;
	return api.get(url, { params });
};

export const getModuleVocakeys = (params) => {
	var url = `${ApiRouters.TEACHER}/Module/ModuleVocakeys`;
	return api.get(url, { params });
};

export const getModuleInClass = (id) => {
	var url = `${ApiRouters.TEACHER}/Class/${id}/ListModule`;
	return api.get(url);
};

export const getReportProcessModule = (params) => {
	var url = `${ApiRouters.TEACHER}/All/Module/ReportProcessModule`;
	return api.get(url, { params });
};

export const updateAuthorizationForTeacher = (body, id) => {
	var url = `${ApiRouters.TEACHER}/AuthorizationForTeacher/${id}`;
	return api.put(url, body);
};
