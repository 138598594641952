const actions = {
  GET_STATISTICS: "GET_STATISTICS",
  GET_STATISTICS_SUCCESS: "GET_STATISTICS_SUCCESS",
  GET_STATISTICS_ERROR: "GET_STATISTICS_ERROR",
  STATISTICS_LOADING_FALSE: 'STATISTICS_LOADING_FALSE',
  //#region CRUD

  getStatistics: model => ({
    type: actions.GET_STATISTICS,
    payload: { model }
  }),

};

export default actions;
