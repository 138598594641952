import { api } from "@iso/utils/axios.configs";
import { ApiRouters } from "@iso/utils/apiRouters";

export const getContents = body => {
  var url = `${ApiRouters.HOMEPAGE}`;
  return api.get(url, { params: body });
};
export const updateContentDetail = body => {
  var url = `${ApiRouters.HOMEPAGE}`;
  return api.put(url, body);
};
export const uploadImage = body => {
  var url = `${ApiRouters.HOMEPAGE}/UploadImage`;
  return api.put(url, body);
};
export const getImage = () => {
  var url = `${ApiRouters.HOMEPAGE}/Image`;
  return api.get(url);
};

