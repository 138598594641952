const actions = {
	GET_SETTINGS: 'GET_SETTINGS',
	GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',

	GET_SETTING_DETAIL: 'GET_SETTING_DETAIL',
	GET_SETTING_DETAIL_SUCCESS: 'GET_SETTING_DETAIL_SUCCESS',

	UPDATE_SETTING: 'UPDATE_SETTING',

	SETTING_LOADING_FALSE: 'SETTING_LOADING_FALSE',
	//#region CRUD

	getSettings: (model) => ({
		type: actions.GET_SETTINGS,
		payload: { model },
	}),

	updateSetting: (id, body, cbSuccess, cbError) => ({
		type: actions.UPDATE_SETTING,
		payload: { id, body },
		cbSuccess,
		cbError,
	}),

	getSettingDetail: (id) => ({
		type: actions.GET_SETTING_DETAIL,
		payload: { id },
	}),
};

export default actions;
