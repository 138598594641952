const actions = {  

  GET_GENERAL_INFO: "GET_GENERAL_INFO",
  GET_GENERAL_INFO_SUCCESS: "GET_GENERAL_INFO_SUCCESS",
  GET_GENERAL_INFO_ERROR: "GET_GENERAL_INFO_ERROR",

  GET_REVIEW_INFO: "GET_REVIEW_INFO",
  GET_REVIEW_INFO_SUCCESS: "GET_REVIEW_INFO_SUCCESS",
  GET_REVIEW_INFO_ERROR: "GET_REVIEW_INFO_ERROR",

  GET_COMPLETE_INFO: "GET_COMPLETE_INFO",
  GET_COMPLETE_INFO_SUCCESS: "GET_COMPLETE_INFO_SUCCESS",
  GET_COMPLETE_INFO_ERROR: "GET_COMPLETE_INFO_ERROR",
  //#region CRUD
  getGeneralInfo: (id, model) => ({
    type: actions.GET_GENERAL_INFO,
    payload: { id, model }
  }),
  getReviewInfo: id => ({
    type: actions.GET_REVIEW_INFO,
    payload: { id }
  }),
  getCompleteInfo: id => ({
    type: actions.GET_COMPLETE_INFO,
    payload: { id }
  }),
  
};

export default actions;
