import { api, exportAxios } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';
import axios from 'axios';

export const exportTopics = () => {
	var url = `${ApiRouters.EXPORT}/TopicTemplate`;
	return axios(exportAxios(url));
};
export const exportSentences = () => {
	var url = `${ApiRouters.EXPORT}/SentenceTemplate`;
	return axios(exportAxios(url));
};
export const exportVocakeys = () => {
	var url = `${ApiRouters.EXPORT}/VocakeyTemplate`;
	return axios(exportAxios(url));
};
export const exportSingleFillOuts = () => {
	var url = `${ApiRouters.EXPORT}/SingleFillOutTemplate`;
	return axios(exportAxios(url));
};
export const exportMultipleFillOuts = () => {
	var url = `${ApiRouters.EXPORT}/MultipleFillOutTemplate`;
	return axios(exportAxios(url));
};

export const exportTopicsWithData = () => {
	var url = `${ApiRouters.EXPORT}/TopicTemplateWithData`;
	return axios(exportAxios(url));
};
export const exportSentencesWithData = () => {
	var url = `${ApiRouters.EXPORT}/SentenceTemplateWithData`;
	return axios(exportAxios(url));
};
export const exportVocakeysWithData = () => {
	var url = `${ApiRouters.EXPORT}/VocakeyTemplateWithData`;
	return axios(exportAxios(url));
};
export const exportSingleFillOutsWithData = () => {
	var url = `${ApiRouters.EXPORT}/SingleFillOutTemplateWithData`;
	return axios(exportAxios(url));
};
export const exportMultipleFillOutsWithData = () => {
	var url = `${ApiRouters.EXPORT}/MultipleFillOutTemplateWithData`;
	return axios(exportAxios(url));
};

export const exportRequestVoca = (params) => {
	var url = `/api/VocabularyRequirement/BO/ExportVocabularyRequirement`;
	return axios(exportAxios(url, params));
};
