import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getGeneralInfo = (learnerId, params) => {
    var url = `${ApiRouters.LEARNINGPROCESS}/${learnerId}/GeneralInfo`;
    return api.get(url, { params });
}
export const getReviewInfo = (learnerId, params) => {
    var url = `${ApiRouters.LEARNINGPROCESS}/${learnerId}/ReviewInfo`;
    return api.get(url, { params });
}
export const getCompleteInfo = (learnerId, params) => {
    var url = `${ApiRouters.LEARNINGPROCESS}/${learnerId}/CompleteInfo`;
    return api.get(url, { params });
}
