import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as grammarDescriptionServices from '@iso/services/grammarDescription';

//#region CRUD
export function* getGrammarDescriptions() {
    yield takeEvery(actions.GET_GRAMMARDESCRIPTIONS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(grammarDescriptionServices.getGrammarDescriptions, model);
            if (response.data.success === true) {
                var data = response.data.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_GRAMMARDESCRIPTIONS_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.GRAMMARDESCRIPTION_LOADING_FALSE })
        }
    })
}
export function* createGrammarDescription() {
    yield takeEvery(actions.CREATE_GRAMMARDESCRIPTION, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(grammarDescriptionServices.createGrammarDescription, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* updateGrammarDescription() {
    yield takeEvery(actions.UPDATE_GRAMMARDESCRIPTION, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(grammarDescriptionServices.updateGrammarDescription, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* deleteGrammarDescription() {
    yield takeEvery(actions.DELETE_GRAMMARDESCRIPTION, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(grammarDescriptionServices.deleteGrammarDescription, id);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* getGrammarDescriptionDetail() {
    yield takeEvery(actions.GET_GRAMMARDESCRIPTION_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(grammarDescriptionServices.getGrammarDescriptionDetail, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_GRAMMARDESCRIPTION_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.GRAMMARDESCRIPTION_LOADING_FALSE })
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(getGrammarDescriptions),
        fork(createGrammarDescription),
        fork(updateGrammarDescription),
        fork(deleteGrammarDescription),
        fork(getGrammarDescriptionDetail),
    ])
}