import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as notificationServices from '@iso/services/notification';

//#region CRUD
export function* getNotifications() {
    yield takeEvery(actions.GET_NOTIFICATIONS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(notificationServices.getNotifications, model);
            if (response.data.success === true) {
                var data = response.data.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_NOTIFICATIONS_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.NOTIFICATION_LOADING_FALSE })
        }
    })
}
export function* sendLearnerNotifications() {
    yield takeEvery(actions.SEND_LEARNER_NOTIFICATIONS, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(notificationServices.sendLearnerNotifications, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}

export function* getNotificationDetail() {
    yield takeEvery(actions.GET_NOTIFICATION_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(notificationServices.getNotificationDetail, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_NOTIFICATION_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.NOTIFICATION_LOADING_FALSE })
        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getNotifications),
        fork(sendLearnerNotifications),
        fork(getNotificationDetail),
    ])
}