import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getSettings = (body) => {
	var url = ApiRouters.SETTING;
	return api.get(url, { params: body });
};
export const updateSetting = (id, body) => {
	var url = `${ApiRouters.SETTING}/${id}`;
	return api.put(url, body);
};
export const getSettingDetail = (id) => {
	var url = `${ApiRouters.SETTING}/${id}`;
	return api.get(url);
};
