import actions from './actions';

const initState = {
	teachers: [],
	teacherDetail: {
		fullName: null,
		email: null,
		dob: null,
		gender: null,
		phoneNumber: null,
		userName: null,
		code: null,
		addedStamp: null,
		isActive: null,
		careerId: null,
		language: null,
		nationId: null,
		provinceId: null,
		districtId: null,
	},
	subcriptionDetail: {},
	subcriptions: [],
	permissions: {},
	loading: false,
	totalItems: 0,
	classes: [],
	managerClass: [],
	folders: [],
	modules: [],
	moduleVocakeys: [],
	nameModule: null,
};

export default function teacherReducer(state = initState, action) {
	switch (action.type) {
		//#region CRUD
		case actions.GET_TEACHERS:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_TEACHERS_SUCCESS:
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, teachers: res, totalItems, loading: false };
		case actions.GET_TEACHERS_ERROR:
			return { ...state, loading: false, teachers: [], totalItems: 0 };

		case actions.GET_CLASS_BY_TEACHER:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_CLASS_BY_TEACHER_SUCCESS:
			let { data: dataClass, totalItems: totalClass } = action.payload;
			let res1 = dataClass ? dataClass : [];
			return { ...state, classes: res1, totalItems: totalClass, loading: false };
		case actions.GET_CLASS_BY_TEACHER_ERROR:
			return { ...state, loading: false, classes: [], totalItems: 0 };

		case actions.GET_CLASS_BY_MANAGER:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_CLASS_BY_MANAGER_SUCCESS:
			let { data: dataManage, totalItems: totalManage } = action.payload;
			let res2 = dataManage ? dataManage : [];
			return { ...state, managerClass: res2, totalItems: totalManage, loading: false };
		case actions.GET_CLASS_BY_MANAGER_ERROR:
			return { ...state, loading: false, managerClass: [], totalItems: 0 };

		case actions.GET_TEACHER_DETAIL:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_TEACHER_DETAIL_SUCCESS: {
			let { data } = action.payload;
			let res = data ? data : {};
			return { ...state, teacherDetail: res, loading: false };
		}

		case actions.GET_SUBCRIPTIONS_TEACHER:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_SUBCRIPTIONS_TEACHER_SUCCESS: {
			let { data, totalItems } = action.payload;
			let res = data ? data : {};
			return { ...state, subcriptions: res, totalItems, loading: false };
		}

		case actions.GET_LEARNVOCAKEYS_TEACHER:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_LEARNVOCAKEYS_TEACHER_SUCCESS: {
			let { data, totalLearnVocakeys } = action.payload;
			let res = data ? data : {};
			return { ...state, learnVocakeys: res, totalLearnVocakeys, loading: false };
		}

		case actions.GET_SUBCRIPTION_TEACHER_DETAIL:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_SUBCRIPTION_DETAIL_TEACHER_SUCCESS: {
			let { data } = action.payload;
			let res = data ? data : {};
			return { ...state, subcriptionDetail: res, loading: false };
		}
		case actions.GET_SUBCRIPTION_DETAIL_TEACHER_ERROR:
			return { ...state, loading: false, subcriptionDetail: {} };

		case actions.GET_FOLDERS:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_FOLDERS_SUCCESS:
			let { data: dataListFolder, totalItems: totalFolder } = action.payload;
			return { ...state, folders: dataListFolder, totalItems: totalFolder, loading: false };

		case actions.GET_MODULES:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_MODULES_SUCCESS:
			let { data: dataListModule, totalItems: totalModule } = action.payload;
			return { ...state, modules: dataListModule, totalItems: totalModule, loading: false };

		case actions.GET_MODULE_VOCAKEYS:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_MODULE_VOCAKEYS_SUCCESS:
			let { data: dataModuleVocakeys, totalItems: totalModuleVocakeys, nameModule } = action.payload;
			return {
				...state,
				moduleVocakeys: dataModuleVocakeys,
				totalItems: totalModuleVocakeys,
				loading: false,
				nameModule,
			};

		case actions.TEACHER_LOADING_FALSE: {
			return { ...state, loading: false };
		}

		default:
			return state;
	}
}
