import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getVocabulary = (params) => {
	var url = `${ApiRouters.VOCABULARY}/Search`;
	return api.get(url, { params });
};

export const createVocabulary = (body) => {
	var url = ApiRouters.VOCABULARY;
	return api.post(url, body);
};

export const updateVocabulary = (id, body) => {
	var url = `${ApiRouters.VOCABULARY}/${id}`;
	return api.put(url, body);
};

export const deleteVocabulary = (id) => {
	var url = `${ApiRouters.VOCABULARY}/${id}`;
	return api.delete(url);
};

export const getClassInVocabulary = (params) => {
	var url = `${ApiRouters.VOCABULARY}/Class`;
	return api.get(url, { params });
};

export const updateOrderClass = (body) => {
	var url = `${ApiRouters.VOCABULARY}/Class`;
	return api.put(url, body);
};
