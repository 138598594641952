import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getDiscountCodes = (params) => {
	var url = ApiRouters.GIFT_CODES;
	return api.get(url, { params });
};

export const checkDiscountCode = (body) => {
	var url = `${ApiRouters.GIFT_CODES}/CheckGiftCode`;
	return api.post(url, body);
};

export const createDiscountCode = (body) => {
	var url = ApiRouters.GIFT_CODES;
	return api.post(url, body);
};

export const updateDiscountCode = (id, body) => {
	var url = `${ApiRouters.GIFT_CODES}/${id}`;
	return api.put(url, body);
};

export const deleteDiscountCode = (id) => {
	var url = `${ApiRouters.GIFT_CODES}/${id}`;
	return api.delete(url);
};

