const actions = {

  IMPORT_TOPICS: "IMPORT_TOPICS",
  IMPORT_SENTENCES: "IMPORT_SENTENCES",
  IMPORT_SENTENCEVOCAKEYS: "IMPORT_SENTENCEVOCAKEYS",
  IMPORT_SINGLEFILLOUTS: "IMPORT_SINGLEFILLOUTS",
  IMPORT_MULTIPLEFILLOUTS: "IMPORT_MULTIPLEFILLOUTS",
  IMPORT_SENTENCEIMAGES: "IMPORT_SENTENCEIMAGES",
  IMPORT_TOPICIMAGES: "IMPORT_TOPICIMAGES",
  IMPORT_MALEAUDIO: "IMPORT_MALEAUDIO",
  IMPORT_FEMALEAUDIO: "IMPORT_FEMALEAUDIO",
  IMPORT_VOCAKEYAUDIO: "IMPORT_VOCAKEYAUDIO",

  IMPORT_LOADING_FALSE: 'IMPORT_LOADING_FALSE',

  START_IMPORT: "START_IMPORT",
  END_IMPORT: "END_IMPORT",
  //#region CRUD

  importTopics: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_TOPICS,
    payload: { model },
    cbSuccess,
    cbError
  }),
  importSentences: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_SENTENCES,
    payload: { model },
    cbSuccess,
    cbError
  }),
  importSentenceVocakeys: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_SENTENCEVOCAKEYS,
    payload: { model },
    cbSuccess,
    cbError
  }),
  importSingleFillOuts: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_SINGLEFILLOUTS,
    payload: { model },
    cbSuccess,
    cbError
  }),
  importMultipleFillOuts: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_MULTIPLEFILLOUTS,
    payload: { model },
    cbSuccess,
    cbError
  }),
  importSentenceImages: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_SENTENCEIMAGES,
    payload: { model },
    cbSuccess,
    cbError
  }),
  importTopicImages: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_TOPICIMAGES,
    payload: { model },
    cbSuccess,
    cbError
  }),
  importMaleAudio: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_MALEAUDIO,
    payload: { model },
    cbSuccess,
    cbError
  }),
  importFemaleAudio: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_FEMALEAUDIO,
    payload: { model },
    cbSuccess,
    cbError
  }),
  importVocakeyAudio: (model, cbSuccess, cbError) => ({
    type: actions.IMPORT_VOCAKEYAUDIO,
    payload: { model },
    cbSuccess,
    cbError
  }),

  startImport: () => ({
    type: actions.START_IMPORT,
  }),

  endImport: () => ({
    type: actions.END_IMPORT,
  }),
};

export default actions;
