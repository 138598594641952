import { api } from "@iso/utils/axios.configs";
import { ApiRouters } from "@iso/utils/apiRouters";

export const getGrammarDescriptions = body => {
  var url = ApiRouters.GRAMMARDESCRIPTION;
  return api.get(url, { params: body });
};
export const createGrammarDescription = body => {
  var url = ApiRouters.GRAMMARDESCRIPTION;
  return api.post(url, body);
};
export const updateGrammarDescription = body => {
  var url = `${ApiRouters.GRAMMARDESCRIPTION}/${body.id}`;
  return api.put(url,body);
};
export const deleteGrammarDescription = id => {
  var url = `${ApiRouters.GRAMMARDESCRIPTION}/${id}`;
  return api.delete(url);
};
export const getGrammarDescriptionDetail = id => {
  var url = `${ApiRouters.GRAMMARDESCRIPTION}/${id}`;
  return api.get(url);
};