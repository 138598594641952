import actions from './actions';

const initState = {
	classDetail: {},
	classProId: null,
	permissions: {},
	loading: false,
	totalItems: 0,
	totalItemsRequest: 0,
	chartClass: {},
	learnerWaitingForClass: [],
	reportListStudent: [],
	reportProcessModule: [],
	listModule: [],
	subCriptionPackagesInClass: null,
	subCriptionPackagesProInClass: null,
	informationDetail: null,
	classInVocabulary: [],
};

export default function classReducer(state = initState, action) {
	switch (action.type) {
		//#region CRUD

		case actions.GET_CHART_DETAIL_CLASS:
			return { ...state, loading: true };

		case actions.GET_CHART_DETAIL_CLASS_SUCCESS: {
			let { data } = action.payload;
			let res = data ? data : {};
			return { ...state, chartClass: res, loading: false };
		}

		case actions.GET_REPORT_DETAIL_CLASS:
			return { ...state, loading: true };

		case actions.GET_REPORT_DETAIL_CLASS_SUCCESS: {
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, reportListStudent: res, totalItems: totalItems, loading: false };
		}

		case actions.GET_REPORT_PROCESS_MODULE:
			return { ...state, loading: true };

		case actions.GET_REPORT_PROCESS_MODULE_SUCCESS: {
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, reportProcessModule: res, totalItems, loading: false };
		}

		case actions.GET_MODULE_INCLASS:
			return { ...state, loading: true };

		case actions.GET_MODULE_INCLASS_SUCCESS: {
			let { data } = action.payload;
			let res = data ? data : [];
			return { ...state, listModule: res, loading: false };
		}

		case actions.GET_SUBCRIPTION_PACKAGES:
			return { ...state, loading: true };

		case actions.GET_SUBCRIPTION_PACKAGES_SUCCESS: {
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, subCriptionPackagesInClass: res, totalItems, loading: false };
		}

		case actions.GET_SUBCRIPTION_PACKAGES_PRO:
			return { ...state, loading: true };

		case actions.GET_SUBCRIPTION_PACKAGES_PRO_SUCCESS: {
			let { data, totalItems, classProId } = action.payload;
			let res = data ? data : [];
			return { ...state, subCriptionPackagesProInClass: res, totalItems, classProId, loading: false };
		}

		case actions.GET_REQUEST_INTO_CLASS:
			return { ...state, loading: true };

		case actions.GET_REQUEST_INTO_CLASS_SUCCESS: {
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, learnerWaitingForClass: res, totalItemsRequest: totalItems, loading: false };
		}

		case actions.GET_CLASS_DETAIL:
			return { ...state, loading: true, totalItems: 0 };

		case actions.GET_CLASS_DETAIL_SUCCESS: {
			let { data } = action.payload;
			let res = data ? data : {};
			return { ...state, classDetail: res, loading: false };
		}

		case actions.GET_INFORMATION_DETAIL:
			return { ...state, loading: true, totalItems: 0 };

		case actions.GET_INFORMATION_DETAIL_SUCCESS: {
			let { data } = action.payload;
			let res = data ? data : {};
			return { ...state, informationDetail: res, loading: false };
		}

		case actions.CLASS_LOADING_FALSE: {
			return { ...state, loading: false };
		}

		default:
			return state;
	}
}
