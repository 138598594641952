import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as teacherServices from '@iso/services/teacher';
import * as classServices from '@iso/services/class';

//#region CRUD
export function* getTeachers() {
	yield takeLatest(actions.GET_TEACHERS, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(teacherServices.getTeachers, model);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_TEACHERS_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.GET_TEACHERS_ERROR,
			});
		}
	});
}

export function* getAllClassByCreater() {
	yield takeLatest(actions.GET_CLASS_BY_TEACHER, function* ({ payload }) {
		const { model, id } = payload;
		try {
			var response = yield call(classServices.getAllClassByCreater, model, id);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_CLASS_BY_TEACHER_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.GET_CLASS_BY_TEACHER_ERROR,
			});
		}
	});
}

export function* getAllClassByManager() {
	yield takeLatest(actions.GET_CLASS_BY_MANAGER, function* ({ payload }) {
		const { model, id } = payload;
		try {
			var response = yield call(classServices.getAllClassByManager, model, id);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_CLASS_BY_MANAGER_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.GET_CLASS_BY_MANAGER_ERROR,
			});
		}
	});
}

export function* getTeacherDetail() {
	yield takeLatest(actions.GET_TEACHER_DETAIL, function* ({ payload }) {
		const { id } = payload;
		try {
			var response = yield call(teacherServices.getTeacherDetail, id);
			if (response.data.success === true) {
				var data = response.data.data;
				yield put({
					type: actions.GET_TEACHER_DETAIL_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({
				type: actions.GET_TEACHER_DETAIL_ERROR,
			});
		}
	});
}

export function* createTeacher() {
	yield takeLatest(actions.CREATE_TEACHER, function* ({ payload, cbSuccess, cbError }) {
		const { model } = payload;
		try {
			var response = yield call(teacherServices.createTeacher, model);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* updateTeacher() {
	yield takeLatest(actions.UPDATE_TEACHER, function* ({ payload, cbSuccess, cbError }) {
		const { model } = payload;
		try {
			var response = yield call(teacherServices.updateTeacher, model);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* deleteTeacher() {
	yield takeLatest(actions.DELETE_TEACHER, function* ({ payload, cbSuccess, cbError }) {
		const { id } = payload;
		try {
			var response = yield call(teacherServices.deleteTeacher, id);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* createSubcription() {
	yield takeLatest(actions.CREATE_SUBCRIPTION_TEACHER, function* ({ payload, cbSuccess, cbError }) {
		const { model, teacherId } = payload;
		try {
			var response = yield call(teacherServices.createSubcription, model, teacherId);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* getSubcriptions() {
	yield takeLatest(actions.GET_SUBCRIPTIONS_TEACHER, function* ({ payload }) {
		const { model, teacherId } = payload;
		try {
			var response = yield call(teacherServices.getSubcriptions, model, teacherId);
			if (response.data.success === true) {
				var { data } = response.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_SUBCRIPTIONS_TEACHER_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({ type: actions.TEACHER_LOADING_FALSE });
		}
	});
}
export function* updateSubcription() {
	yield takeLatest(actions.UPDATE_SUBCRIPTION_TEACHER, function* ({ payload, cbSuccess, cbError }) {
		const { model } = payload;
		try {
			var response = yield call(teacherServices.updateSubcription, model);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* getSubcriptionDetail() {
	yield takeLatest(actions.GET_SUBCRIPTION_TEACHER_DETAIL, function* ({ payload }) {
		const { id } = payload;
		try {
			var response = yield call(teacherServices.getSubcriptionDetail, id);
			if (response.data.success === true) {
				var data = response.data.data;
				yield put({
					type: actions.GET_SUBCRIPTION_DETAIL_TEACHER_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({
				type: actions.GET_SUBCRIPTION_DETAIL_TEACHER_ERROR,
			});
		}
	});
}
export function* getLearnVocakeys() {
	yield takeLatest(actions.GET_LEARNVOCAKEYS_TEACHER, function* ({ payload }) {
		const { model, teacherId } = payload;
		try {
			var response = yield call(teacherServices.getLearnVocakeys, model, teacherId);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalLearnVocakeys = response.data.data.total;
				yield put({
					type: actions.GET_LEARNVOCAKEYS_SUCCESS,
					payload: { data, totalLearnVocakeys },
				});
			}
		} catch (e) {
			yield put({ type: actions.TEACHER_LOADING_FALSE });
		}
	});
}

// v4
export function* getFolders() {
	yield takeLatest(actions.GET_FOLDERS, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(teacherServices.getFolders, model);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_FOLDERS_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.TEACHER_LOADING_FALSE,
			});
		}
	});
}

export function* getModules() {
	yield takeLatest(actions.GET_MODULES, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(teacherServices.getModules, model);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_MODULES_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.TEACHER_LOADING_FALSE,
			});
		}
	});
}

export function* getModuleVocakeys() {
	yield takeLatest(actions.GET_MODULE_VOCAKEYS, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(teacherServices.getModuleVocakeys, model);
			if (response.data.success === true) {
				var data = response.data.data?.listVocakey;
				var totalItems = response.data.data?.totalVocakey;
				var nameModule = response.data.data?.name;

				yield put({
					type: actions.GET_MODULE_VOCAKEYS_SUCCESS,
					payload: { data, totalItems, nameModule },
				});
			}
		} catch (e) {
			yield put({
				type: actions.TEACHER_LOADING_FALSE,
			});
		}
	});
}
export function* updateAuthorizationForTeacher() {
	yield takeLatest(actions.UPDATE_AUTHOR_FEATURE, function* ({ payload, cbError }) {
		const { model, id } = payload;
		try {
			var response = yield call(teacherServices.updateAuthorizationForTeacher, model, id);
			if (response.data.success === true) {
				yield getTeacherDetail();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(getTeachers),
		fork(createTeacher),
		fork(updateTeacher),
		fork(deleteTeacher),
		fork(getTeacherDetail),
		fork(createSubcription),
		fork(getSubcriptions),
		fork(updateSubcription),
		fork(getSubcriptionDetail),
		fork(getLearnVocakeys),
		fork(getAllClassByCreater),
		fork(getAllClassByManager),
		fork(getFolders),
		fork(getModules),
		fork(getModuleVocakeys),
		fork(updateAuthorizationForTeacher),
	]);
}
