import actions from "./actions";

const initState = {  
  subcriptionPackages: [],
  subcriptionPackageDetail: {},
  permissions: {},
  loading: false,
  totalItems: 0,

};

export default function subcriptionPackageReducer(state = initState, action) {
  switch (action.type) {
    //#region CRUD
    case actions.GET_SUBCRIPTIONPACKAGES:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_SUBCRIPTIONPACKAGES_SUCCESS:
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, subcriptionPackages: res, totalItems, loading: false };

    case actions.GET_SUBCRIPTIONPACKAGE_DETAIL:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_SUBCRIPTIONPACKAGE_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, subcriptionPackageDetail: res, loading: false };
    }
    case actions.SUBCRIPTIONPACKAGE_LOADING_FALSE:{
      return { ...state, loading: false, subcriptionPackageDetail: {} };
    }

    default:
      return state;
  }
}
