import actions from './actions';

const initState = {
	vocabulary: [],
	classInVocabulary: [],
	loading: false,
	totalItems: 0,
};

export default function teacherReducer(state = initState, action) {
	switch (action.type) {
		case actions.GET_VOCABULARY:
			return { ...state, loading: true, totalItems: 0 };
		case actions.GET_VOCABULARY_SUCCESS:
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, vocabulary: res, totalItems, loading: false };

		case actions.GET_CLASS_IN_VOCABULARY:
			return { ...state, loading: true };

		case actions.GET_CLASS_IN_VOCABULARY_SUCCESS: {
			let { listData, total } = action.payload;
			let res = listData ? listData : [];
			return { ...state, classInVocabulary: res, totalItems: total, loading: false };
		}

		case actions.VOCABULARY_LOADING_FALSE: {
			return { ...state, loading: false };
		}

		default:
			return state;
	}
}
