import actions from "./actions";

const initState = {  
  sentences: [],
  sentenceDetail: {},
  sentenceVocakeys: [],
  sentenceVocakeyDetail: {},
  permissions: {},
  loading: false,
  totalItems: 0,

};

export default function sentenceReducer(state = initState, action) {
  switch (action.type) {
    //#region CRUD
    case actions.GET_SENTENCES:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_SENTENCES_SUCCESS:
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, sentences: res, totalItems, loading: false };

    case actions.GET_SENTENCE_DETAIL:
      return { ...state, loading: true, totalItems: 0 };

    case actions.GET_SENTENCE_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, sentenceDetail: res, loading: false };
    }

    case actions.GET_SENTENCEVOCAKEYS:
      return { ...state, loading: true, totalItems: 0 };
    case actions.GET_SENTENCEVOCAKEYS_SUCCESS: {
      let { data, totalItems  } = action.payload;
      let res = data ? data : {};
      return { ...state, sentenceVocakeys: res, totalItems, loading: false };
    }

    case actions.GET_SENTENCEVOCAKEY_DETAIL:
      return { ...state, loading: true, totalItems: 0 };
    case actions.GET_SENTENCEVOCAKEY_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, sentenceVocakeyDetail: res, loading: false };
    }

    case actions.SENTENCE_LOADING_FALSE:{
      return { ...state,  loading: false };
    }

    default:
      return state;
  }
}
