import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getReportIssue = (params) => {
	var url = `${ApiRouters.REPORT_ISSUE}/SearchReportErrorSentence`;
	return api.get(url, { params });
};

export const updateIssueStatus = (body) => {
	var url = `${ApiRouters.REPORT_ISSUE}/UpdateStatusReportErrorSentence`;
	return api.put(url, body);
};

export const getErrorSentence = (params) => {
	var url = `${ApiRouters.REPORT_ISSUE}/Search`;
	return api.get(url, { params });
};

export const createErrorSentence = (body) => {
	var url = ApiRouters.REPORT_ISSUE;
	return api.post(url, body);
};

export const updateErrorSentence = (body) => {
	var url = ApiRouters.REPORT_ISSUE;
	return api.put(url, body);
};

export const deleteErrorSentence = (id) => {
	var url = `${ApiRouters.REPORT_ISSUE}/${id}`;
	return api.delete(url);
};
