import actions from "./actions";

const initState = {
  generalInfo: {},
  reviewInfo: {},
  completeInfo: {},
  loading: false,
};

export default function learningProcessReducer(state = initState, action) {
  switch (action.type) {
  
    case actions.GET_GENERAL_INFO:
      return { ...state, loading: true };
    case actions.GET_GENERAL_INFO_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, generalInfo: res, loading: false };
    }

    case actions.GET_REVIEW_INFO:
      return { ...state, loading: true };
    case actions.GET_REVIEW_INFO_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, reviewInfo: res, loading: false };
    }

    case actions.GET_COMPLETE_INFO:
      return { ...state, loading: true };
    case actions.GET_COMPLETE_INFO_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, completeInfo: res, loading: false };
    }

    default:
      return state;
  }
}
