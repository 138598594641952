import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as subscriptionRequestServices from '@iso/services/subscriptionRequest';

//#region CRUD
export function* getSubscriptionRequests() {
    yield takeLatest(actions.GET_SUBSCRIPTIONREQUESTS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(subscriptionRequestServices.getSubscriptionRequests, model);
            if (response.data.success === true) {
                var data = response.data.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_SUBSCRIPTIONREQUESTS_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({ type: actions.SUBSCRIPTIONREQUEST_LOADING_FALSE })
        }
    })
}

export function* getSubscriptionTeacherRequests() {
    yield takeLatest(actions.GET_SUBSCRIPTIONTEACHERREQUESTS, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(subscriptionRequestServices.getSubscriptionTeacherRequests, model);
            if (response.data.success === true) {
                var data1 = response.data.data.data;
                var totalItems1 = response.data.data.total;
                yield put({
                    type: actions.GET_SUBSCRIPTIONTEACHERREQUESTS_SUCCESS,
                    payload: { data1, totalItems1 }
                })
            }
        } catch (e) {
            yield put({ type: actions.SUBSCRIPTIONTEACHERREQUEST_LOADING_FALSE })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getSubscriptionRequests),
        fork(getSubscriptionTeacherRequests),
    ])
}