import * as requestVocaServices from '@iso/services/requestVoca';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';

export function* getRequestVocaList() {
	yield takeLatest(actions.GET_REQUEST_VOCA_LIST, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(requestVocaServices.getRequestVocaList, model);
			if (response.data.success === true) {
				var data = response.data.data.data;

				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_REQUEST_VOCA_LIST_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({
				type: actions.REQUEST_VOCA_LOADING_FALSE,
			});
		}
	});
}

export default function* rootSaga() {
	yield all([fork(getRequestVocaList)]);
}
