const actions = {
  GET_SUBCRIPTIONPACKAGES: "GET_SUBCRIPTIONPACKAGES",
  GET_SUBCRIPTIONPACKAGES_SUCCESS: "GET_SUBCRIPTIONPACKAGES_SUCCESS",

  GET_SUBCRIPTIONPACKAGE_DETAIL: "GET_SUBCRIPTIONPACKAGE_DETAIL",
  GET_SUBCRIPTIONPACKAGE_DETAIL_SUCCESS: "GET_SUBCRIPTIONPACKAGE_DETAIL_SUCCESS",

  CREATE_SUBCRIPTIONPACKAGE: "CREATE_SUBCRIPTIONPACKAGE",
  UPDATE_SUBCRIPTIONPACKAGE: "UPDATE_SUBCRIPTIONPACKAGE",
  DELETE_SUBCRIPTIONPACKAGE: "DELETE_SUBCRIPTIONPACKAGE",

  SUBCRIPTIONPACKAGE_LOADING_FALSE: 'SUBCRIPTIONPACKAGE_LOADING_FALSE',
  //#region CRUD

  getSubcriptionPackages: model => ({
    type: actions.GET_SUBCRIPTIONPACKAGES,
    payload: { model }
  }),

  createSubcriptionPackage: (model, cbSuccess, cbError) => ({
    type: actions.CREATE_SUBCRIPTIONPACKAGE,
    payload: { model },
    cbSuccess,
    cbError
  }),
  updateSubcriptionPackage: (model, cbSuccess, cbError) => ({
    type: actions.UPDATE_SUBCRIPTIONPACKAGE,
    payload: { model },
    cbSuccess,
    cbError
  }),
  deleteSubcriptionPackage: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_SUBCRIPTIONPACKAGE,
    payload: { id },
    cbSuccess,
    cbError
  }),
  getSubcriptionPackageDetail: (model) => ({
    type: actions.GET_SUBCRIPTIONPACKAGE_DETAIL,
    payload: { model }
  }),
};

export default actions;
