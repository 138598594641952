import { api } from "@iso/utils/axios.configs";
import { ApiRouters } from "@iso/utils/apiRouters";

export const getSentences = body => {
  var url = ApiRouters.SENTENCE;
  return api.get(url, { params: body });
};
export const createSentence = body => {
  var url = ApiRouters.SENTENCE;
  return api.post(url, body);
};
export const updateSentence = (body, id) => {
  var url = `${ApiRouters.SENTENCE}/${id}`;
  return api.put(url, body);
};
export const deleteSentence = id => {
  var url = `${ApiRouters.SENTENCE}/${id}`;
  return api.delete(url);
};
export const getSentenceDetail = id => {
  var url = `${ApiRouters.SENTENCE}/${id}`;
  return api.get(url);
};
export const createSentenceVocakey = (body, sentenceId) => {
  var url = `${ApiRouters.SENTENCE}/${sentenceId}/SentenceVocakey`;
  return api.post(url, body);
};
export const getSentenceVocakeys = (params, sentenceId) => {
  var url = `${ApiRouters.SENTENCE}/${sentenceId}/SentenceVocakey`;
  return api.get(url, { params });
};
export const getSentenceVocakeyDetail = (id, params) => {
  var url = `${ApiRouters.SENTENCE}/SentenceVocakey/${id}`;
  return api.get(url, { params });
}
export const updateSentenceVocakey = (body, id) => {
  var url = `${ApiRouters.SENTENCE}/SentenceVocakey/${id}`;
  return api.put(url,body);
};
export const deleteSentenceVocakey = id => {
  var url = `${ApiRouters.SENTENCE}/SentenceVocakey/${id}`;
  return api.delete(url);
};
