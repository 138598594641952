import actions from './actions';

const initState = {
	levels: [],
	levelDetail: {},
	permissions: {},
	loading: false,
	totalItems: 0,
};

export default function levelReducer(state = initState, action) {
	switch (action.type) {
		//#region CRUD
		case actions.GET_LEVELS:
			return { ...state, loading: true, totalItems: 0 };

		case actions.GET_LEVELS_SUCCESS:
			let { data, totalItems } = action.payload;
			let res = data ? data : [];
			return { ...state, levels: res, totalItems, loading: false };

		case actions.GET_ALL_LEVELS:
			return { ...state, loading: true };

		case actions.GET_ALL_LEVELS_SUCCESS:
			let { data: re } = action.payload;
			let result = re ? re : [];
			return { ...state, levels: result, loading: false };

		case actions.GET_LEVEL_DETAIL:
			return { ...state, loading: true, totalItems: 0 };

		case actions.GET_LEVEL_DETAIL_SUCCESS: {
			let { data } = action.payload;
			let res = data ? data : {};
			return { ...state, levelDetail: res, loading: false };
		}
		case actions.LEVEL_LOADING_FALSE: {
			return { ...state, loading: false };
		}

		default:
			return state;
	}
}
