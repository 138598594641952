const actions = {
  GET_IMAGE_SLIDER: "GET_IMAGE_SLIDER",
  GET_IMAGE_SLIDER_SUCCESS: "GET_IMAGE_SLIDER_SUCCESS",

  ADD_IMAGE_SLIDER: "ADD_IMAGE_SLIDER",
  UPDATE_IMAGE_SLIDER: "UPDATE_IMAGE_SLIDER",
  UPDATE_SLIDER_MULTI: "UPDATE_SLIDER_MULTI",
  IMAGE_SLIDER_LOADING_FALSE: 'IMAGE_SLIDER_LOADING_FALSE',
  //#region CRUD

  addImageSlider: (model, cbSuccess, cbError) => ({
    type: actions.ADD_IMAGE_SLIDER,
    payload: { model },
    cbSuccess,
    cbError
  }),
  updateImageSlider: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_IMAGE_SLIDER,
    payload: { model },
    _cbSuccess, _cbError
  }),
  updateSliderMulti: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_SLIDER_MULTI,
    payload: { model },
    _cbSuccess, _cbError
  }),
  getImageSlider: id => ({
    type: actions.GET_IMAGE_SLIDER,
    payload: { id }
  }),


  //#region Static page
  GET_STATICPAGES: "GET_STATICPAGES",
  GET_STATICPAGES_SUCCESS: "GET_STATICPAGES_SUCCESS",

  GET_STATICPAGE_DETAIL: "GET_STATICPAGE_DETAIL",
  GET_STATICPAGE_DETAIL_SUCCESS: "GET_STATICPAGE_DETAIL_SUCCESS",
  UPDATE_STATICPAGE: "UPDATE_STATICPAGE",

  STATICPAGE_LOADING_FALSE: 'STATICPAGE_LOADING_FALSE',
  //#region CRUD

  getStaticPages: model => ({
    type: actions.GET_STATICPAGES,
    payload: { model }
  }),
  updateStaticPage: (model, cbSuccess, cbError) => ({
    type: actions.UPDATE_STATICPAGE,
    payload: { model },
    cbSuccess,
    cbError
  }),
  getStaticPageDetail: id => ({
    type: actions.GET_STATICPAGE_DETAIL,
    payload: { id }
  }),
  //#endregion


  //#region Image trial
  GET_IMAGE_TRIAL: "GET_IMAGE_TRIAL",
  GET_IMAGE_TRIAL_SUCCESS: "GET_IMAGE_TRIAL_SUCCESS",

  UPDATE_IMAGE_TRIAL: "UPDATE_IMAGE_TRIAL",
  IMAGE_TRIAL_LOADING_FALSE: 'IMAGE_SLIDER_LOADING_FALSE',
  //#region CRUD

  updateImageTrial: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_IMAGE_TRIAL,
    payload: { model },
    _cbSuccess, _cbError
  }),

  getImageTrial: id => ({
    type: actions.GET_IMAGE_TRIAL,
    payload: { id }
  }),

  //#endregion
};

export default actions;
