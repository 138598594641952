const actions = {
	GET_LEVELS: 'GET_LEVELS',
	GET_LEVELS_SUCCESS: 'GET_LEVELS_SUCCESS',

	GET_ALL_LEVELS: 'GET_ALL_LEVELS',
	GET_ALL_LEVELS_SUCCESS: 'GET_ALL_LEVELS_SUCCESS',

	GET_LEVEL_DETAIL: 'GET_LEVEL_DETAIL',
	GET_LEVEL_DETAIL_SUCCESS: 'GET_LEVEL_DETAIL_SUCCESS',

	CREATE_LEVEL: 'CREATE_LEVEL',
	UPDATE_LEVEL: 'UPDATE_LEVEL',
	DELETE_LEVEL: 'DELETE_LEVEL',

	LEVEL_LOADING_FALSE: 'LEVEL_LOADING_FALSE',
	//#region CRUD

	getLevels: (model) => ({
		type: actions.GET_LEVELS,
		payload: { model },
	}),

	getAllLevels: () => ({
		type: actions.GET_ALL_LEVELS,
	}),

	createLevel: (model, cbSuccess, cbError) => ({
		type: actions.CREATE_LEVEL,
		payload: { model },
		cbSuccess,
		cbError,
	}),
	updateLevel: (model, id, cbSuccess, cbError) => ({
		type: actions.UPDATE_LEVEL,
		payload: { model, id },
		cbSuccess,
		cbError,
	}),
	deleteLevel: (id, cbSuccess, cbError) => ({
		type: actions.DELETE_LEVEL,
		payload: { id },
		cbSuccess,
		cbError,
	}),
	getLevelDetail: (id) => ({
		type: actions.GET_LEVEL_DETAIL,
		payload: { id },
	}),
};

export default actions;
