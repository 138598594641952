const actions = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATION_DETAIL: "GET_NOTIFICATION_DETAIL",
  GET_NOTIFICATION_DETAIL_SUCCESS: "GET_NOTIFICATION_DETAIL_SUCCESS",
  SEND_LEARNER_NOTIFICATIONS: "SEND_LEARNER_NOTIFICATIONS",
  NOTIFICATION_LOADING_FALSE: 'NOTIFICATION_LOADING_FALSE',
  //#region CRUD

  getNotifications: model => ({
    type: actions.GET_NOTIFICATIONS,
    payload: { model }
  }),
  sendLearnerNotifications: (model, cbSuccess, cbError) => ({
    type: actions.SEND_LEARNER_NOTIFICATIONS,
    payload: { model },
    cbSuccess,
    cbError
  }),
  getNotificationDetail: id => ({
    type: actions.GET_NOTIFICATION_DETAIL,
    payload: { id }
  }),
};

export default actions;
