import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as reportServices from '@iso/services/report';
import * as moment from 'moment';

//#region LEARNING QUALITY
export function* getLearningQuality() {
    yield takeLatest(actions.GET_LEARNING_QUALITY, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.getLearningQuality, model);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_LEARNING_QUALITY_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_LEARNING_QUALITY_ERROR,
            })
        }
    })
}
//#region DEMOGRAPHIC_REPORT_BY_AGE
export function* getDemographicReportByAge() {
    yield takeLatest(actions.GET_DEMOGRAPHIC_REPORT_BY_AGE, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.getDemographicReportByAge, model);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_DEMOGRAPHIC_REPORT_BY_AGE_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_DEMOGRAPHIC_REPORT_BY_AGE_ERROR,
            })
        }
    })
}
//#region DEMOGRAPHIC_REPORT_BY_GENDER
export function* getDemographicReportByGender() {
    yield takeLatest(actions.GET_DEMOGRAPHIC_REPORT_BY_GENDER, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.getDemographicReportByGender, model);
            if (response.data.success === true){
                var data = response.data.data;
                yield put({
                    type: actions.GET_DEMOGRAPHIC_REPORT_BY_GENDER_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_DEMOGRAPHIC_REPORT_BY_GENDER_ERROR,
            })
        }
    })
}
//#region DEMOGRAPHIC_REPORT_BY_CAREER
export function* getDemographicReportByCareer() {
    yield takeLatest(actions.GET_DEMOGRAPHIC_REPORT_BY_CAREER, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.getDemographicReportByCareer, model);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_DEMOGRAPHIC_REPORT_BY_CAREER_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_DEMOGRAPHIC_REPORT_BY_CAREER_ERROR,
            })
        }
    })
}
//#region DEMOGRAPHIC_REPORT_BY_NATION
export function* getDemographicReportByNation() {
    yield takeLatest(actions.GET_DEMOGRAPHIC_REPORT_BY_NATION, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.getDemographicReportByNation, model);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_DEMOGRAPHIC_REPORT_BY_NATION_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_DEMOGRAPHIC_REPORT_BY_NATION_ERROR,
            })
        }
    })
}
//#region DEMOGRAPHIC_REPORT_BY_PROVINCE
export function* getDemographicReportByProvince() {
    yield takeLatest(actions.GET_DEMOGRAPHIC_REPORT_BY_PROVINCE, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.getDemographicReportByProvince, model);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_DEMOGRAPHIC_REPORT_BY_PROVINCE_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_DEMOGRAPHIC_REPORT_BY_PROVINCE_ERROR,
            })
        }
    })
}
//#region USERSTATISTIC_REPORT_BY_REGISTERTYPE
export function* getUserStatisticReportByRegisterType() {
    yield takeLatest(actions.GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.getUserStatisticReportByRegisterType, model);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_USERSTATISTIC_REPORT_BY_REGISTERTYPE_ERROR,
            })
        }
    })
}
//#region USERSTATISTIC_REPORT_BY_TRAFFIC
export function* getUserStatisticReportByTraffic() {
    yield takeLatest(actions.GET_USERSTATISTIC_REPORT_BY_TRAFFIC, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.getUserStatisticReportByTraffic, model);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_USERSTATISTIC_REPORT_BY_TRAFFIC_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_USERSTATISTIC_REPORT_BY_TRAFFIC_ERROR,
            })
        }
    })
}
//#region USERSTATISTIC_REPORT_BY_PACKAGE
export function* getUserStatisticReportByPackage() {
    yield takeLatest(actions.GET_USERSTATISTIC_REPORT_BY_PACKAGE, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.getUserStatisticReportByPackage, model);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_USERSTATISTIC_REPORT_BY_PACKAGE_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_USERSTATISTIC_REPORT_BY_PACKAGE_ERROR,
            })
        }
    })
}
//#region EXPORT
export function* exportLearningQualityReport() {
    yield takeLatest(actions.EXPORT_LEARNINGQUALITYREPORT, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.exportLearningQualityReport, model);
            if (response.data) {
                let date = moment(new Date()).format("DD_MM_YYYY");
                const fileName = `Bao_Cao_Chat_Luong_Hoc_Tap_${date}.xlsx`;
                yield exportSuccess(fileName, response.data);
            }
        } catch (e) {
            yield put({ type: actions.EXPORT_ERROR })
        }
    })
}
export function* exportDemographicReportByAge() {
    yield takeLatest(actions.EXPORT_DEMOGRAPHICREPORTBYAGE, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.exportDemographicReportByAge, model);
            if (response.data) {
                let date = moment(new Date()).format("DD_MM_YYYY");
                const fileName = `Bao_Cao_Dan_So_Hoc_Theo_Tuoi_${date}.xlsx`;
                yield exportSuccess(fileName, response.data);
            }
        } catch (e) {
            yield put({ type: actions.EXPORT_ERROR })
        }
    })
}
export function* exportDemographicReportByGender() {
    yield takeLatest(actions.EXPORT_DEMOGRAPHICREPORTBYGENDER, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.exportDemographicReportByGender, model);
            if (response.data) {
                let date = moment(new Date()).format("DD_MM_YYYY");
                const fileName = `Bao_Cao_Dan_So_Hoc_Theo_Gioi_Tinh_${date}.xlsx`;
                yield exportSuccess(fileName, response.data);
            }
        } catch (e) {
            yield put({ type: actions.EXPORT_ERROR })
        }
    })
}
export function* exportDemographicReportByCareer() {
    yield takeLatest(actions.EXPORT_DEMOGRAPHICREPORTBYCAREER, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.exportDemographicReportByCareer, model);
            if (response.data) {
                let date = moment(new Date()).format("DD_MM_YYYY");
                const fileName = `Bao_Cao_Dan_So_Hoc_Theo_Nghe_Nghiep_${date}.xlsx`;
                yield exportSuccess(fileName, response.data);
            }
        } catch (e) {
            yield put({ type: actions.EXPORT_ERROR })
        }
    })
}
export function* exportDemographicReportByNation() {
    yield takeLatest(actions.EXPORT_DEMOGRAPHICREPORTBYNATION, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.exportDemographicReportByNation, model);
            if (response.data) {
                let date = moment(new Date()).format("DD_MM_YYYY");
                const fileName = `Bao_Cao_Dan_So_Hoc_Theo_Quoc_Gia_${date}.xlsx`;
                yield exportSuccess(fileName, response.data);
            }
        } catch (e) {
            yield put({ type: actions.EXPORT_ERROR })
        }
    })
}
export function* exportDemographicReportByProvince() {
    yield takeLatest(actions.EXPORT_DEMOGRAPHICREPORTBYPROVINCE, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.exportDemographicReportByProvince, model);
            if (response.data) {
                let date = moment(new Date()).format("DD_MM_YYYY");
                const fileName = `Bao_Cao_Dan_So_Hoc_Theo_Thanh_Pho_${date}.xlsx`;
                yield exportSuccess(fileName, response.data);
            }
        } catch (e) {
            yield put({ type: actions.EXPORT_ERROR })
        }
    })
}
export function* exportUserStatisticReportByRegisterType() {
    yield takeLatest(actions.EXPORT_USERSTATISTICREPORTBYREGISTERTYPE, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.exportUserStatisticReportByRegisterType, model);
            if (response.data) {
                let date = moment(new Date()).format("DD_MM_YYYY");
                const fileName = `Bao_Cao_Thong_Ke_Su_Dung_App_Theo_Cach_Dang_Ky_${date}.xlsx`;
                yield exportSuccess(fileName, response.data);
            }
        } catch (e) {
            yield put({ type: actions.EXPORT_ERROR })
        }
    })
}
export function* exportUserStatisticReportByPackage() {
    yield takeLatest(actions.EXPORT_USERSTATISTICREPORTBYPACKAGE, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.exportUserStatisticReportByPackage, model);
            if (response.data) {
                let date = moment(new Date()).format("DD_MM_YYYY");
                const fileName = `Bao_Cao_Thong_Ke_Su_Dung_App_Theo_Loai_Tai_Khoan_${date}.xlsx`;
                yield exportSuccess(fileName, response.data);
            }
        } catch (e) {
            yield put({ type: actions.EXPORT_ERROR })
        }
    })
}
export function* exportUserStatisticReportByTraffic() {
    yield takeLatest(actions.EXPORT_USERSTATISTICREPORTBYTRAFFIC, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(reportServices.exportUserStatisticReportByTraffic, model);
            if (response.data) {
                let date = moment(new Date()).format("DD_MM_YYYY");
                const fileName = `Bao_Cao_Thong_Ke_Su_Dung_App_Theo_Luu_Luong_Truy_Cap_${date}.xlsx`;
                yield exportSuccess(fileName, response.data);
            }
        } catch (e) {
            yield put({ type: actions.EXPORT_ERROR })
        }
    })
}
function* exportSuccess(fileName, data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    yield put({ type: actions.EXPORT_ERROR });
}
export default function* rootSaga() {
    yield all([
        fork(getLearningQuality),
        fork(getDemographicReportByAge),
        fork(getDemographicReportByGender),
        fork(getDemographicReportByCareer),
        fork(getDemographicReportByNation),
        fork(getDemographicReportByProvince),
        fork(getUserStatisticReportByRegisterType),
        fork(getUserStatisticReportByTraffic),
        fork(getUserStatisticReportByPackage),

        fork(exportLearningQualityReport),
        fork(exportDemographicReportByAge),
        fork(exportDemographicReportByGender),
        fork(exportDemographicReportByCareer),
        fork(exportDemographicReportByNation),
        fork(exportDemographicReportByProvince),
        fork(exportUserStatisticReportByRegisterType),
        fork(exportUserStatisticReportByPackage),
        fork(exportUserStatisticReportByTraffic),
    ])
}