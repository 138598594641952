import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as levelServices from '@iso/services/level';

//#region CRUD
export function* getLevels() {
	yield takeEvery(actions.GET_LEVELS, function* ({ payload }) {
		const { model } = payload;
		try {
			var response = yield call(levelServices.getLevels, model);
			if (response.data.success === true) {
				var data = response.data.data.data;
				var totalItems = response.data.data.total;
				yield put({
					type: actions.GET_LEVELS_SUCCESS,
					payload: { data, totalItems },
				});
			}
		} catch (e) {
			yield put({ type: actions.LEVEL_LOADING_FALSE });
		}
	});
}

export function* getAllLevels() {
	yield takeEvery(actions.GET_ALL_LEVELS, function* ({ payload }) {
		try {
			var response = yield call(levelServices.getAllLevels);
			if (response.data.success === true) {
				var data = response.data.data;
				yield put({
					type: actions.GET_ALL_LEVELS_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({ type: actions.LEVEL_LOADING_FALSE });
		}
	});
}

export function* createLevel() {
	yield takeEvery(actions.CREATE_LEVEL, function* ({ payload, cbSuccess, cbError }) {
		const { model } = payload;
		try {
			var response = yield call(levelServices.createLevel, model);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* updateLevel() {
	yield takeEvery(actions.UPDATE_LEVEL, function* ({ payload, cbSuccess, cbError }) {
		const { model, id } = payload;
		try {
			var response = yield call(levelServices.updateLevel, model, id);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* deleteLevel() {
	yield takeEvery(actions.DELETE_LEVEL, function* ({ payload, cbSuccess, cbError }) {
		const { id } = payload;
		try {
			var response = yield call(levelServices.deleteLevel, id);
			if (response.data.success === true) {
				yield cbSuccess();
			}
		} catch (e) {
			if (e.response && e.response.data) yield cbError(e.response.data.ErrorMessage);
			else yield cbError();
		}
	});
}
export function* getLevelDetail() {
	yield takeEvery(actions.GET_LEVEL_DETAIL, function* ({ payload }) {
		const { id } = payload;
		try {
			var response = yield call(levelServices.getLevelDetail, id);
			if (response.data.success === true) {
				var data = response.data.data;
				yield put({
					type: actions.GET_LEVEL_DETAIL_SUCCESS,
					payload: { data },
				});
			}
		} catch (e) {
			yield put({ type: actions.LEVEL_LOADING_FALSE });
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(getLevels),
		fork(getAllLevels),
		fork(createLevel),
		fork(updateLevel),
		fork(deleteLevel),
		fork(getLevelDetail),
	]);
}
