import actions from "./actions";

const initState = {
  loading: false,
  totalItems: 0,
  loadingImport: false,
};

export default function importReducer(state = initState, action) {
  switch (action.type) {
    //#region CRUD

    case actions.START_IMPORT:
      return { ...state, loadingImport: true };
    case actions.END_IMPORT:
      return { ...state, loadingImport: false };

    default:
      return state;
  }
}
